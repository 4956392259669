import routes from "./payRoutes";
import store from "./payStore";

export default {
  install(Vue, options) {
    options.router.addRoute("/", ...routes);
    options.store.registerModule("Pay", store);
  },
};

