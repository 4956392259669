import constants from "./profileConstants";
import call from "@/service/http";
import { EventBus } from "@/utils/eventBus";
import AuthService from "@/modules/auth/authService";

export default {
  namespaced: true,
  state: {
    profile: {},
    dependant: {},
    dependants: [],
    facility: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    profileGetters: (state) => (val) => state[val],
  },
  actions: {
    getProfile: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.profile, data)
        .then((res) => {
          if (res.data.status_code === 2000) {
            AuthService.logout();
          } else {
            commit("MUTATE", { state: "profile", value: res.data.data });
           
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDependants: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.dependants, data)
        .then((res) => {
          commit("MUTATE", { state: "dependants", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    getOneDependant: ({ commit }, data) => {
      // commit("SET_LOADING", true, { root: true });
      call("post", constants.dependant, data)
        .then((res) => {
          commit("MUTATE", { state: "dependant", value: res.data.data });
          // commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    saveDependantFacility: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.savedepFacility, data.allData)
        .then((res) => {
          if (res.data.status_code === 1000) {
            EventBus.$emit("changeDependant");
            if (data.rating === true) {
              EventBus.$emit("openRatingBox");
            }
            dispatch("getOneDependant", {
              member_no: data.allData.mem_no,
              dependant_id: data.allData.dependant_no,
              source: "WEB",
            });

            commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("error");
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    getMemberFacility: ({ commit }, data) => {
      // commit("SET_LOADING", true, { root: true });
      call("post", constants.memfacility, data)
        .then((res) => {
          commit("MUTATE", { state: "facility", value: res.data.data });
          // commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {});
    },
  },
};
