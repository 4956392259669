import dashboard from "@/modules/dashboard/views/dashboard";
import chat from "@/modules/chat/components/chatPage"
import Auth from "@/modules/auth/middleware/Auth";
export default [
  {
    path: "/chat",
    component: dashboard,

    children: [{ path: "/", name: "Chat", component: chat, meta: {middleware:[Auth]} }],
  },
];
