import dashboard from "@/modules/dashboard/views/dashboard";
import profileSummary from "@/modules/profile/components/profileSummary";
import dependantInfo from "@/modules/profile/components/dependantInfo";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/profile",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "profileSummary",
        component: profileSummary,
        meta: { middleware: [Auth]},
      },
      {
        path: "/dependant/:code/:memberNo",
        name: "dependantInfo",
        component: dependantInfo,
        meta: { middleware: [Auth]},
      },
    ],
  },
];

