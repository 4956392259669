import dashboard from "@/modules/dashboard/views/dashboard";
import facilitiesDashboard from "@/modules/facilities/components/facilitiesDashboard";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/facilities",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "facilitiesDashboard",
        component: facilitiesDashboard,
        meta: { middleware: [Auth]},
      },
    ],
  },
];

