// this.$refs.paymentForm.validate();
<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="height: 100%; background-color: #eeeeee"
  >
    <v-card color="basil">
      <v-card-title class="text-center justify-center py-6">
        <h1 class="font-weight-bold text-h2 basil--text">PAY ARREARS</h1>
      </v-card-title>

      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item">
          <v-row v-if="tab === 0"
            ><v-col></v-col
            ><v-col
              ><v-card class="mx-4" width="1000" flat>
                <!-- <v-alert
                  dense
                  v-if="$store.getters['Pay/alert'].status"
                  :type="
                    $store.getters['Pay/alert'].status === 'success'
                      ? 'success'
                      : 'error'
                  "
                  class="mb-2 mt-3"
                >
                  {{ $store.getters["Pay/alert"].message }}
                </v-alert> -->
                <v-card-text class="text-justify mt-2 pb-0">
                  <v-form
                    v-model="isValid"
                    ref="paymentForm"
                    class="mt-1"
                    v-on:submit.prevent
                  >
                    <v-text-field
                      dense
                      outlined
                      label="Name"
                      placeholder=""
                      v-model="formData.name"
                      ref="phone"
                      readonly
                    />
                    <v-text-field
                      dense
                      outlined
                      label="ID Number"
                      placeholder=""
                      v-model="formData.id_number"
                      ref="idNo"
                      :rules="rules.required"
                      readonly
                    />

                    <v-text-field
                      dense
                      outlined
                      label="Phone"
                      placeholder=""
                      v-model="formData.phone"
                      required
                      :rules="rules.required"
                    />

                    <v-text-field
                      dense
                      outlined
                      label="Amount"
                      ref="payeeamount"
                      placeholder="Enter Amount to Pay"
                      v-model="formData.amount"
                      :rules="rules.required"
                    />
                  </v-form>
                </v-card-text>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="4"> </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="btn-success btn-block white--text"
                        color="success"
                        @click="payNHIF()"
                      >
                        <span>Pay for Self</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="4"> </v-col>
                  </v-row>
                </v-card-actions> </v-card></v-col
            ><v-col></v-col
          ></v-row>

          <v-row v-if="tab === 1"
            ><v-col></v-col
            ><v-col
              ><v-card class="mx-4" width="1000" flat>
                <!-- <v-alert
                  dense
                  v-if="$store.getters['Pay/alert'].status"
                  :type="
                    $store.getters['Pay/alert'].status === 'success'
                      ? 'success'
                      : 'error'
                  "
                  class="mb-2 mt-3"
                >
                  {{ $store.getters["Pay/alert"].message }}
                </v-alert> -->
                <v-card-text class="text-justify mt-2 pb-0">
                  <v-form
                    v-model="isFormValid"
                    ref="form"
                    class="mt-1"
                    v-on:submit.prevent
                  >
                    <v-text-field
                      dense
                      outlined
                      label="ID Number"
                      placeholder=""
                      v-model="otherFormData.id_number"
                      ref="idNo"
                      :rules="rules.required"
                      @change="getNames(otherFormData.id_number)"
                    />
                    <v-text-field
                      dense
                      outlined
                      label="Name"
                      placeholder=""
                      v-model="otherFormData.name"
                      ref="phone"
                      :rules="rules.required"
                      disabled
                    />

                    <v-text-field
                      dense
                      outlined
                      label="Phone"
                      placeholder=""
                      v-model="otherFormData.phone"
                      ref="phone"
                      :rules="rules.required"
                      disabled
                    />

                    <v-text-field
                      dense
                      outlined
                      label="Amount"
                      placeholder="Enter Amount to Pay"
                      v-model="otherFormData.amount"
                      ref="amount"
                      :rules="rules.required"
                    />
                  </v-form>
                </v-card-text>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="4"> </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="btn-success btn-block white--text"
                        color="success"
                        @click="payForOther()"
                      >
                        <span>Pay for Other</span>
                      </v-btn>
                    </v-col>
                    <!-- <v-col cols="4"
                      ><v-btn color="error" class="mr-4" @click="reset()">
                        Reset Form
                      </v-btn>
                    </v-col> -->
                  </v-row>
                </v-card-actions>
              </v-card></v-col
            ><v-col></v-col
          ></v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="dialog" persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Please Wait as We Retrieve the Names
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successDialog"
      max-width="550"
      max-height="1000"
      persistent
    >
      <v-card>
        <v-card-actions
          ><v-spacer /><v-icon
            class="vicon"
            size="100"
            :color="
              $store.getters['Pay/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
          >
            {{
              $store.getters["Pay/alert"].status === "success"
                ? "mdi-check-circle-outline"
                : "mdi-close-circle-outline"
            }}</v-icon
          ><v-spacer
        /></v-card-actions>

        <v-card-title class="text-h4"
          ><v-spacer />
          {{
            $store.getters["Pay/alert"].status === "success"
              ? "Success"
              : "Error"
          }}
          <v-spacer />
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1 white--text" @click="redirect()">
            ok
          </v-btn>
          <!-- <v-btn color="green darken-1 white--text" @click="showAlert()">
            test
          </v-btn> -->
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import { AuthService } from "@/modules/auth";
export default {
  name: "pay",
  components: {},

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Profile/getProfile", {
        id_number: AuthService.idNo,
        source: "WEB",
        type: "1",
      });
    });
  },
  data() {
    return {
      isValid: false,
      isFormValid: false,
      successDialog:false,
      show: true,
      dialog: false,
      formData: {
        name: "",
        id_number: "",
        phone: "",
        amount: "",
      },
      otherFormData: {
        name: "",
        id_number: "",
        phone: "",
        amount: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      tab: null,
      items: ["Pay for Myself", "Pay for Other"],
    };
  },
  computed: {
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
    names() {
      return this.$store.getters["Pay/payGetters"]("names");
    },
    routeParams() {
      return this.$route.query.q;
    },
  },
  methods: {
    reset() {
      // this.$refs.form.reset();
      this.$refs["form"][0].reset();
    },
    redirect() {
      this.$router.push({ name: "mainDashboard" });
    },
    getNames(val) {
      this.dialog = true;
      const data = {
        national_id: val,
        source: "WEB",
      };
      this.$store.dispatch("Pay/getNames", data);
    },
    payNHIF() {
      if (!this.isValid) {
        this.$refs.paymentForm.validate();
        this.$toast.error("All Fields Are Required");
      } else {
        this.$store.dispatch(
          "Pay/payNhif",

          {
            clientMSISDN: this.formData.phone,
            amount: this.formData.amount,
            pay_by_id: this.formData.id_number,
            clientIDNumber: this.formData.id_number,
            billDesc: "ARREARS",
            clientName: this.formData.name,
            clientEmail: "",
            source: "WEB",
          }
        );
      }
    },
    payForOther() {
      if (!this.isFormValid) {
        this.$refs.paymentForm.validate();
        this.$toast.error("All Fields Are Required");
      } else {
        const newPhone = this.otherFormData.phone.replace(/^0|254/g, "");
        const NP = "254" + newPhone;
        this.$store.dispatch(
          "Pay/payNhif",

          {
            clientMSISDN: NP,
            amount: this.otherFormData.amount,
            pay_by_id: this.profile.id_number,
            clientIDNumber: this.otherFormData.id_number,
            billDesc: "ARREARS",
            clientName: this.otherFormData.name,
            clientEmail: "",
            source: "WEB",
          }
        );
      }
    },
  },
  mounted() {
    EventBus.$on("openDialog", (e) => {
      this.$toast.success(e);
      this.successDialog = true;
    });

    EventBus.$on("closeSuccessDialog", (e) => {
      this.$toast.error(e);
      this.successDialog = true;
    });
    EventBus.$on("resetForm", () => {
      // this.$refs.payeeamount.reset();
      this.$refs.otherPaymentForm.reset();
    });
    EventBus.$on("closeDialog", () => {
      // this.$refs.payeeamount.reset();
      this.dialog = false;
    });
  },
  watch: {
    profile: {
      handler: function () {
        if (this.profile !== undefined && this.tab === 0) {
          this.formData.name =
            this.profile.first_name + " " + this.profile.last_name;
          this.formData.id_number = this.profile.id_number;
          this.formData.phone = this.profile.phone;
        }
      },
    },
    names: {
      handler: function () {
        if (this.names !== undefined && this.tab === 1) {
          this.otherFormData.name =
            this.names.first_name + " " + this.names.last_name;
          this.otherFormData.phone = this.names.phone;
        }
      },
    },
  },
};
</script>

<style scoped>
.pay-btn {
  color: white;
  width: 300px;
}
.title {
  font-size: 13px;
  font-weight: 800;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
</style>
