<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    color="#26c6da"
    style="background-color: #dcdcdc"
    v-on:keydown.enter="register()"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-sheet elevation="2" class="pa-10">
          <v-row>
            <v-col cols="12" md="12">
              <v-card flat>
                <v-alert
                  dense
                  v-if="$store.getters['Auth/alert'].status"
                  :type="
                    $store.getters['Auth/alert'].status === 'success'
                      ? 'success'
                      : 'error'
                  "
                  class="mb-10"
                >
                  {{ $store.getters["Auth/alert"].message }}
                </v-alert>
                <v-img
                  src="../../../assets/nhif-logo.png"
                  height="100"
                  contain
                  class="d-flex flex-row justify-center mt-n8"
                />
                <v-divider class="mt-n2" />

                <v-card-text class="text-justify mt-2 pb-0">
                  <div class="d-flex flex-row justify-center font-weight-black">
                    Dont Have an Account ? Register Below
                  </div>

                  <v-form
                    v-model="isValid"
                    ref="registerForm"
                    class="mt-1"
                    v-on:submit.prevent
                  >
                    <v-text-field
                      dense
                      outlined
                      label="Mobile Number"
                      placeholder="Enter your Mobile Number"
                      v-model="formData.phone"
                      ref="phone"
                      :rules="rules.required"
                    />

                    <v-autocomplete
                      :items="count"
                      v-model="countyformData.county"
                      outlined
                      dense
                      :item-value="(item) => item"
                      :item-text="(item) => item.county_name"
                      label="search your county"
                      @change="getBranches(countyformData.county)"
                      :rules="rules.required"
                    ></v-autocomplete>
                    <v-autocomplete
                      :items="
                        newBranches.length !== 0 ? newBranches : ['Loading']
                      "
                      v-model="countyformData.branch"
                      outlined
                      dense
                      :item-value="(item) => item"
                      :item-text="(item) => item.branch_name"
                      label="search your branch"
                      :rules="rules.required"
                    ></v-autocomplete>

                    <v-select
                      :items="['Male', 'Female']"
                      v-model="formData.gender"
                      outlined
                      dense
                      placeholder=""
                      label="Gender"
                      :rules="rules.required"
                      :menu-props="{
                        maxHeight: 100,
                        overflow: 'hidden',
                      }"
                    ></v-select>
                    <v-select
                      :items="['EMPLOYED', 'SELF-EMPLOYED']"
                      v-model="formData.emp_name"
                      outlined
                      dense
                      placeholder=""
                      label="Employment Status"
                      :rules="rules.required"
                      :menu-props="{
                        maxHeight: 100,
                        overflow: 'hidden',
                      }"
                    ></v-select>

                    <v-text-field
                      dense
                      outlined
                      label="First Name"
                      placeholder="Enter your First Name"
                      v-model="formData.first_name"
                      ref="first_name"
                      :rules="rules.required"
                    />
                    <v-text-field
                      dense
                      outlined
                      label="Middle Name"
                      placeholder="Enter your Middle Name"
                      v-model="formData.middle_name"
                      ref="middle_name"
                      :rules="rules.required"
                    />
                    <v-text-field
                      dense
                      outlined
                      label="Last Name"
                      placeholder="Enter your Last Name"
                      v-model="formData.last_name"
                      ref="last_name"
                      :rules="rules.required"
                    />

                    <v-autocomplete
                      :items="['Single', 'Married']"
                      v-model="formData.marital_status"
                      outlined
                      dense
                      placeholder=""
                      label="Marital Status"
                      :rules="rules.required"
                      :menu-props="{
                        maxHeight: 100,
                        overflow: 'hidden',
                      }"
                    ></v-autocomplete>
                    <v-text-field
                      dense
                      outlined
                      label="Email Address"
                      placeholder="Enter your Email Address"
                      v-model="formData.email"
                      ref="email"
                      :rules="rules.email"
                    />
                  </v-form>
                </v-card-text>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        :disabled="!checkFormDataBranch"
                        class="btn-success btn-block white--text"
                        color="#4dbd74"
                        @click="register()"
                      >
                        <span>Submit</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>

                <div class="alert text-muted mt-n3">
                  Already Registered ?
                  <v-btn text class="blue--text" @click="redirect()"
                    >Login</v-btn
                  >
                </div>

                <div class="alert text-muted mt-n6">
                  For queries ,call NHIF Toll Free Line
                  <strong> 0800 720 601</strong>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Redirecting
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { AuthService } from "@/modules/auth";
// import { EventBus } from "@/utils/eventBus";
export default {
  name: "AddDetails",

  data: function () {
    return {
      isValid: false,
      isFormValid: false,
      showRegForm: false,
      dialog: false,
      formData: {
        email: "",
        marital_status: "",
        gender: "",
        phone: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        branch_no: "",
        emp_name: "",
        source: "WEB",
      },
      countyformData: {
        county: {},
        branch: {},
      },
      count: [
        { county_id: "46", county_name: "NYAMIRA", capital: "NYAMIRA" },

        { county_id: "47", county_name: "NAIROBI", capital: "NAIROBI" },

        { county_id: "48", county_name: "DIASPORA", capital: null },
      ],

      newCounties: [],
      newBranches: [],
      placeHolder: ["search your county"],
    };
  },

  computed: {
    rules() {
      return {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        required: [(v) => !!v || "Field is required"],
      };
    },
    counties() {
      return this.$store.getters["Facilities/facilitiesGetters"]("counties");
    },
    branches() {
      return this.$store.getters["Facilities/facilitiesGetters"]("branches");
    },

    checkPhoneNumber() {
      return this.formData.phone !== "" ? true : false;
    },
    checkBranch() {
      const Branch = Object.keys(this.countyformData.branch).length;
      if (Branch !== 0) {
        return true;
      } else {
        return false;
      }
    },
    checkFormDataBranch() {
      return this.formData.branch_no !== "" && this.formData.marital_status !== "" &&  this.formData.email !== "" ? true : false;
    },
  },

  methods: {
    register: function () {
      if (!this.isValid) {
        this.$refs.registerForm.validate();
      } else {
        this.formData.id_number = localStorage.getItem("id_number");
        this.formData.birth_date = localStorage.getItem("dob");
        this.formData.branch_no = this.countyformData.branch.branch_no;
        this.$store.dispatch("Auth/register", this.formData);
      }
    },

    redirect() {
      this.$router.push({ name: "Login" });
    },
    getBranches(val) {
      this.$store.dispatch("Facilities/getBranches", {
        source: "WEB",
        county_code: val.county_id,
      });
    },
  },
  watch: {
    checkPhoneNumber: {
      handler: function () {
        // this.$store.dispatch("Facilities/getCounties", {});
        this.$store.dispatch("Facilities/getCounties", {
          source: "WEB",
          text: "",
          phone: this.formData.phone,
          id: this.formData.id_number,
        });
      },
    },
    counties: {
      handler: function () {
        if (this.counties.length > 0) {
          this.newCounties = [...this.counties];
        }
      },
    },
    branches: {
      handler: function () {
        if (this.branches.length > 0) {
          this.newBranches = [...this.branches];
        }
      },
    },
    checkBranch: {
      handler: function () {
        if (this.checkBranch) {
          this.formData.branch_no = this.countyformData.branch.branch_no;
        }
      },
    },
  },
  mounted() {},
};
</script>

<style scoped>
.my-custom-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
