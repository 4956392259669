import dashboard from "@/modules/dashboard/views/dashboard";
import mainDashboard from "@/modules/dashboard/components/mainDashboard";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/dashboard",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "mainDashboard",
        component: mainDashboard,
        meta: { middleware: [Auth]},
      },
    ],
  },
];
