import dashboard from "@/modules/dashboard/views/dashboard";
import preauth from "@/modules/preauth/components/preauthList";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/preauth",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "preauth",
        props: route => ({ query: route.query.q }),
        component: preauth,
        meta: { middleware: [Auth]},
      },
    ],
  },
];

