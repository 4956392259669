import constants from "./facilitiesConstants";
import call from "@/service/http";
import { EventBus } from "@/utils/eventBus";

export default {
  namespaced: true,
  state: {
    counties: [],
    facilities: [],
    branches:[],
    facilityDetails:{},
  
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    facilitiesGetters: (state) => (val) => state[val],
  },
  actions: {
    getCounties: ({ commit }, data) => {
      call("post", constants.counties, data)
        .then((res) => {
          commit("MUTATE", { state: "counties", value: res.data.data });
        })
        .catch((err) => {
          console.log(err)
        });
    },
    getFacilities: ({ commit }, data) => {
      call("post", constants.facilities, data)
        .then((res) => {
          commit("MUTATE", { state: "facilities", value: res.data.data });
        })
        .catch(() => {});
    },
    saveFacility: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.save, data.allData)
        .then((res) => {
          // console.log(res)
          if (res.data.status_code === 1000) {
            EventBus.$emit("updateFacility");
            if(data.rating === true) {
              EventBus.$emit("openRatingBox");
            }
            // dispatch('Profile/getProfile')
          commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("error");
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("error");
        });
    },
    getBranches: ({ commit }, data) => {
      call("post", constants.branches, data)
        .then((res) => {
          commit("MUTATE", { state: "branches", value: res.data.data });
        })
        .catch(() => {});
    },
    submitRating: ({ commit }, data) => {
      call("post", constants.rating, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            EventBus.$emit("openSnackBar");
          } else {
            EventBus.$emit("error");
            EventBus.$emit("closeDialog");
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("error");
        });
    },
    getFacilityDetails: ({ commit }, data) => {
      call("post", constants.facilityDetails, data)
        .then((res) => {
          commit("MUTATE", { state: "facilityDetails", value: res.data.data });
        })
        .catch(() => {});
    },
    updateFacility: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.update, data.allData)
        .then((res) => {
          // console.log(res)
          if (res.data.status_code === 1000) {
            EventBus.$emit("updateFacility",res.data.status_desc);
            if(data.rating === true) {
              EventBus.$emit("openRatingBox");
            }
            // dispatch('Profile/getProfile')
          commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("error");
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("error");
        });
    },
   
  },
};
