<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    color="#26c6da"
    style="background-color: #dcdcdc"
    v-on:keydown.enter="Validate()"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-sheet elevation="2" class="pa-10">
          <v-row>
            <v-col cols="12" md="12">
              <v-card flat>
                <v-alert
                  dense
                  v-if="$store.getters['Auth/alert'].status"
                  :type="
                    $store.getters['Auth/alert'].status === 'success'
                      ? 'success'
                      : 'error'
                  "
                  class="mb-10"
                >
                  {{ $store.getters["Auth/alert"].message }}
                </v-alert>
                <v-img
                  src="../../../assets/nhif-logo.png"
                  height="100"
                  contain
                  class="d-flex flex-row justify-center mt-n8"
                />
                <v-divider class="mt-n2" />

                <v-card-text class="text-justify mt-2 pb-0">
                  <div class="d-flex flex-row justify-center font-weight-black">
                    Dont Have an Account ? Register Below
                  </div>

                  <v-form
                    v-model="isFormValid"
                    ref="validateForm"
                    class="mt-1"
                    v-on:submit.prevent
                  >
                    <v-text-field
                      dense
                      outlined
                      label=" National ID Number"
                      placeholder="Enter your ID Number"
                      v-model="validateFormData.id_number"
                      ref="email"
                      :rules="rules.required"
                    />

                    <!--Date of Birth-->

                    <v-row>
                      <v-col cols="12" md="12" sm="12">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="validateFormData.dob"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              v-model="validateFormData.dob"
                              dense
                              outlined
                              label="Birth Date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              :rules="rules.required"
                              v-bind="attrs"
                              v-on="on"
                            ></v-combobox>
                          </template>
                          <v-date-picker
                            v-model="validateFormData.dob"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(validateFormData.dob)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <!--date picker end-->
                  </v-form>
                </v-card-text>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                      :disabled="validateFormData.dob === ''"
                        class="btn-success btn-block white--text"
                        color="#4dbd74"
                        @click="Validate()"
                      >
                        <span>Begin Registration</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>

                <div class="alert text-muted mt-n3">
                  Already Registered ?
                  <v-btn text class="blue--text" @click="redirect()"
                    >Login</v-btn
                  >
                </div>

                <div class="alert text-muted mt-n6">
                  For queries ,call NHIF Toll Free Line
                  <strong> 0800 720 601</strong>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Redirecting
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
export default {
  name: "Register-page",

  data: function () {
    return {
      isFormValid: false,
      showRegForm: false,
      menu:false,
      validateFormData: {
        dob: "",
        id_number: "",
        source: "WEB",
      },

      dialog: false,
    };
  },

  computed: {
    rules() {
      return {
        required: [(v) => !!v || "Field is required"],
      };
    },
  },

  methods: {
    Validate: function () {
      if (!this.isFormValid) {
        this.$refs.validateForm.validate();
      } else {
        localStorage.setItem("id_number", this.validateFormData.id_number);
        localStorage.setItem("dob", this.validateFormData.dob);
        this.$store.dispatch("Auth/validate", this.validateFormData);
      }
    },
    redirect() {
      this.$router.push({ name: "Login" });
    },
  },

  mounted() {
    EventBus.$on("redirecttoDetailsPage", () => {
      if (this.$store.getters["Auth/alert"].message === "Please Wait ..") {
        setTimeout(() => {
          this.dialog = true;
          // Set the loading prop to false once the data is loaded
        }, 2000);
        setInterval(() => {
          this.$router.push({ name: "AddDetails" });
        }, 3000);
      }
    });
  },
};
</script>

<style scoped></style>
