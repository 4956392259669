<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    color="#26c6da"
    style="background-color: #dcdcdc"
    v-on:keydown.enter="submitQuery()"
  >
    <v-row class="mt-6">
      <v-col md="4" sm="12" offset-md="4">
        <v-sheet elevation="2" class="pa-10">
          <v-row>
            <v-col cols="12" md="12">
              <v-card flat>
                <v-alert
                  dense
                  v-if="$store.getters['Auth/alert'].status"
                  :type="
                    $store.getters['Auth/alert'].status === 'success'
                      ? 'success'
                      : 'error'
                  "
                  class="mb-10"
                >
                  {{ $store.getters["Auth/alert"].message }}
                </v-alert>
                <v-img
                  src="../../../assets/nhif-logo.png"
                  height="100"
                  contain
                  class="d-flex flex-row justify-center mt-n8"
                />
                <v-divider class="mt-n2" />
                <v-card-text class="text-justify mt-2 pb-0">
                  <v-form v-model="isValid" ref="queryForm" v-on:submit.prevent>
                    <v-text-field
                      dense
                      outlined
                      label=" National ID Number"
                      placeholder="Enter your ID Number"
                      v-model="formData.id_number"
                      ref="national_id"
                      :rules="rules.required"
                    />
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      label="Mobile Number"
                      placeholder="Enter your Phone Number"
                      v-model="formData.phone"
                      ref="number"
                      :rules="rules.required"
                    />
                    <v-text-field
                      dense
                      outlined
                      label="Email"
                      placeholder="Enter your Email"
                      v-model="formData.email"
                      ref="email"
                      :rules="rules.required"
                    />
                    <v-textarea
                      dense
                      outlined
                      label="Question"
                      placeholder="Enter Your Question"
                      v-model="formData.query"
                      ref="question"
                      :rules="rules.required"
                    />
                  </v-form>
                </v-card-text>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        class="btn-success btn-block white--text"
                        color="#4dbd74"
                        @click="submitQuery()"
                      >
                        <span>Submit Question</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <div class="alert text-muted mt-n3">
                  Already Registered ?
                  <v-btn text class="blue--text ml-n2" @click="redirect()"
                    >Login</v-btn
                  >
                </div>

                <div class="alert text-muted mt-n8">
                  For queries ,call NHIF Toll Free Line
                  <strong> 0800 720 601</strong>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";

export default {
  name: "NeedHelp",
  data: function () {
    return {
      isValid: false,

      formData: {
        id_number: "",
        query: "",
        email: "",
        phone: "",
        source: "WEB",
      },

      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  mounted() {
    EventBus.$on("otp-verification", () => {
      this.dialog = true;
    });
  },

  computed: {},
  methods: {
    submitQuery: function () {
      if (!this.isValid) {
        this.$refs.queryForm.validate();
      } else {
        this.$store.dispatch("Auth/submitQuery", this.formData);
        this.$refs.queryForm.reset()
      }
    },
    redirect() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");

.v-input__append-outer {
  margin-top: 8px;
}
</style>
