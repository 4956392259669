import call from "@/service/http";
import constants from "./chatConstants";
import { EventBus } from "@/utils/eventBus";
export default {
  namespaced: true,
  state: {
    chats: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    chatGetters: (state) => (val) => state[val],
  },
  actions: {
    getChats: ({ commit }, data) => {
      call("post", constants.chats, data)
        .then((res) => {
          commit("MUTATE", { state: "chats", value: res.data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendQuery: ({ commit, dispatch }, data) => {
      call("post", constants.sendQuery, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            dispatch("getChats", {
              phone: data.phone,
              source: "WEB",
            });
            EventBus.$emit("closeDialog");
          } else {
            EventBus.$emit("closeDialog");
            EventBus.$emit("dialogMessage");
          }
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("error", err);
        });
    },
  },
};
