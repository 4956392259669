const appName = "nhif";

class AuthService {
  constructor() {
    this.token = window.localStorage.getItem(`${appName}_token`);
    this.user = window.localStorage.getItem(`${appName}_user`);
    this.idNo = window.localStorage.getItem(`${appName}_idNo`);
    this.phone = window.localStorage.getItem(`${appName}_phone`);
  }

  check() {
    return !!this.token;
  }

  setUser(user) {
    window.localStorage.setItem(`${appName}_names`, JSON.stringify(user));
    this.user = user;
  }

  removeSetUser() {
    window.localStorage.removeItem(`${appName}_user`);
  }

  login(token, user, idNo, phone) {
    const recentLoginTime = Math.floor(Date.now() / 1000);

    window.localStorage.setItem(`${appName}_token`, token);
    window.localStorage.setItem(`${appName}_user`, user);
    window.localStorage.setItem(`${appName}_idNo`, idNo);
    window.localStorage.setItem(`${appName}_phone`, phone);

    window.localStorage.setItem("recentLoginTime", recentLoginTime);

    this.token = token;
    this.user = user;
    this.idNo = idNo;
    this.phone = phone;

    location.reload();
  }

  logout() {
    window.localStorage.removeItem(`${appName}_token`);
    window.localStorage.removeItem(`${appName}_user`);
    window.localStorage.removeItem(`${appName}_idNo`);
    window.localStorage.removeItem(`${appName}_phone`);
    window.localStorage.removeItem(`${appName}_names`);
    window.localStorage.removeItem("dob");
    window.localStorage.removeItem("id_number");
    window.localStorage.removeItem("recentLoginTime");
    window.location.href = "/";
  }
  timerLogout() {
    window.localStorage.removeItem(`${appName}_token`);
    window.localStorage.removeItem(`${appName}_user`);
    window.localStorage.removeItem(`${appName}_idNo`);
    window.localStorage.removeItem(`${appName}_phone`);
    window.localStorage.removeItem(`${appName}_names`);
    window.localStorage.removeItem("dob");
    window.localStorage.removeItem("id_number");
    window.localStorage.removeItem("recentLoginTime");
    window.location.href = "/";
  }

 
}

export default new AuthService();
