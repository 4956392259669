var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"background-color":"#dcdcdc"},attrs:{"fluid":"","color":"#26c6da"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.register()}}},[_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"12","offset-md":"4"}},[_c('v-sheet',{staticClass:"pa-10",attrs:{"elevation":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"flat":""}},[(_vm.$store.getters['Auth/alert'].status)?_c('v-alert',{staticClass:"mb-10",attrs:{"dense":"","type":_vm.$store.getters['Auth/alert'].status === 'success'
                    ? 'success'
                    : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Auth/alert"].message)+" ")]):_vm._e(),_c('v-img',{staticClass:"d-flex flex-row justify-center mt-n8",attrs:{"src":require("../../../assets/nhif-logo.png"),"height":"100","contain":""}}),_c('v-divider',{staticClass:"mt-n2"}),_c('v-card-text',{staticClass:"text-justify mt-2 pb-0"},[_c('div',{staticClass:"d-flex flex-row justify-center font-weight-black"},[_vm._v(" Dont Have an Account ? Register Below ")]),_c('v-form',{ref:"registerForm",staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-text-field',{ref:"phone",attrs:{"dense":"","outlined":"","label":"Mobile Number","placeholder":"Enter your Mobile Number","rules":_vm.rules.required},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('v-autocomplete',{attrs:{"items":_vm.count,"outlined":"","dense":"","item-value":(item) => item,"item-text":(item) => item.county_name,"label":"search your county","rules":_vm.rules.required},on:{"change":function($event){return _vm.getBranches(_vm.countyformData.county)}},model:{value:(_vm.countyformData.county),callback:function ($$v) {_vm.$set(_vm.countyformData, "county", $$v)},expression:"countyformData.county"}}),_c('v-autocomplete',{attrs:{"items":_vm.newBranches.length !== 0 ? _vm.newBranches : ['Loading'],"outlined":"","dense":"","item-value":(item) => item,"item-text":(item) => item.branch_name,"label":"search your branch","rules":_vm.rules.required},model:{value:(_vm.countyformData.branch),callback:function ($$v) {_vm.$set(_vm.countyformData, "branch", $$v)},expression:"countyformData.branch"}}),_c('v-select',{attrs:{"items":['Male', 'Female'],"outlined":"","dense":"","placeholder":"","label":"Gender","rules":_vm.rules.required,"menu-props":{
                      maxHeight: 100,
                      overflow: 'hidden',
                    }},model:{value:(_vm.formData.gender),callback:function ($$v) {_vm.$set(_vm.formData, "gender", $$v)},expression:"formData.gender"}}),_c('v-select',{attrs:{"items":['EMPLOYED', 'SELF-EMPLOYED'],"outlined":"","dense":"","placeholder":"","label":"Employment Status","rules":_vm.rules.required,"menu-props":{
                      maxHeight: 100,
                      overflow: 'hidden',
                    }},model:{value:(_vm.formData.emp_name),callback:function ($$v) {_vm.$set(_vm.formData, "emp_name", $$v)},expression:"formData.emp_name"}}),_c('v-text-field',{ref:"first_name",attrs:{"dense":"","outlined":"","label":"First Name","placeholder":"Enter your First Name","rules":_vm.rules.required},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}}),_c('v-text-field',{ref:"middle_name",attrs:{"dense":"","outlined":"","label":"Middle Name","placeholder":"Enter your Middle Name","rules":_vm.rules.required},model:{value:(_vm.formData.middle_name),callback:function ($$v) {_vm.$set(_vm.formData, "middle_name", $$v)},expression:"formData.middle_name"}}),_c('v-text-field',{ref:"last_name",attrs:{"dense":"","outlined":"","label":"Last Name","placeholder":"Enter your Last Name","rules":_vm.rules.required},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}}),_c('v-autocomplete',{attrs:{"items":['Single', 'Married'],"outlined":"","dense":"","placeholder":"","label":"Marital Status","rules":_vm.rules.required,"menu-props":{
                      maxHeight: 100,
                      overflow: 'hidden',
                    }},model:{value:(_vm.formData.marital_status),callback:function ($$v) {_vm.$set(_vm.formData, "marital_status", $$v)},expression:"formData.marital_status"}}),_c('v-text-field',{ref:"email",attrs:{"dense":"","outlined":"","label":"Email Address","placeholder":"Enter your Email Address","rules":_vm.rules.email},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1)],1),_c('v-card-actions',{},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-success btn-block white--text",attrs:{"disabled":!_vm.checkFormDataBranch,"color":"#4dbd74"},on:{"click":function($event){return _vm.register()}}},[_c('span',[_vm._v("Submit")])])],1)],1)],1),_c('div',{staticClass:"alert text-muted mt-n3"},[_vm._v(" Already Registered ? "),_c('v-btn',{staticClass:"blue--text",attrs:{"text":""},on:{"click":function($event){return _vm.redirect()}}},[_vm._v("Login")])],1),_c('div',{staticClass:"alert text-muted mt-n6"},[_vm._v(" For queries ,call NHIF Toll Free Line "),_c('strong',[_vm._v(" 0800 720 601")])])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Redirecting "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }