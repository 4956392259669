import routes from "./facilitiesRoutes";
import store from "./facilitiesStore";

export default {
  install(Vue, options) {
    options.router.addRoute("/", ...routes);
    options.store.registerModule("Facilities", store);
  },
};

