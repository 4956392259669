import dashboard from "@/modules/dashboard/views/dashboard";
import pay from "@/modules/pay/components/pay.vue";
import arrears from "@/modules/pay/components/arrears.vue";
import penalties from "@/modules/pay/components/penalties.vue";
import Auth from "@/modules/auth/middleware/Auth";
export default [
  {
    path: "",
    component: dashboard,
    children: [
      {
        path: "/pay",
        name: "Pay",
        component: pay,
        // props: (route) => ({ query: route.query.q }),
        meta: { middleware: [Auth] },
      },
      {
        path: "/arrears",
        name: "Arrears",
        component: arrears,
        // props: (route) => ({ query: route.query.q }),
        meta: { middleware: [Auth] },
      },
      {
        path: "/penalties",
        name: "Penalties",
        component: penalties,
        // props: (route) => ({ query: route.query.q }),
        meta: { middleware: [Auth] },
      },
    ],
  },
];
