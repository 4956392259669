var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"height":"100%","background-color":"#eeeeee"},attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndUp ? true : true}},[_c('v-card',{staticClass:"mt-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"card-text"},[_c('v-card',{attrs:{"color":"","flat":""}},[_c('v-card',{attrs:{"color":"","flat":"","loading":_vm.loading}},[_c('div',{staticClass:"d-flex flex-row mt-1"},[_c('div',{staticClass:"px-2 mt-0 mb-1"},[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"primary","size":"46"}},[_vm._v("NHIF")]),_c('span',{staticClass:"text-h6 ml-2 font-weight-bold"},[_vm._v("NHIF")])],1),_c('div',{staticClass:"px-2 mt-3"})])]),_c('v-list',{staticClass:"my-list",attrs:{"color":"#f1f3f6","v-scroll":_vm.onScroll}},[_vm._l((_vm.newChats
              ? _vm.newChats
              : ['Type Something Below']),function(item,i){return _c('v-list-item',{key:i},[(item.direction === 1)?_c('v-list-item-avatar',{style:(item.direction === 1
                  ? ''
                  : 'position: absolute; top: 0; right: 0;'),attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text"},[_vm._v("NHIF")])]):_vm._e(),_c('v-list-item-content',{class:item.direction === 0 ? 'text-end' : 'text-start'},[_c('div',{class:item.direction === 0 ? 'text-message' : 'nhif-message',style:(item.direction === 1
                    ? ''
                    : 'position: absolute; top: 0; right: 0;')},[_vm._v(" "+_vm._s(item.text)+" "),(item.direction === 0)?_c('span',{staticClass:"mt-1 ml-2 mr-0 green--text"},[_c('v-icon',{attrs:{"x-small":"","color":"green"}},[_vm._v(_vm._s(item.query_status === 0 ? "mdi-check" : "mdi-check-all"))]),_vm._v(_vm._s(item.query_status === 0 ? "sent" : "seen"))],1):_vm._e()]),_c('span',{staticClass:"chat-font-style",style:(item.direction === 1 ? 'margin-top:5px' : 'margin-top:40px;')},[_vm._v(" "+_vm._s(_vm.formatQueryTime(item.createdon))+" ")])])],1)}),_c('v-list-item-action',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],attrs:{"fab":"","bottom":"","right":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)],2),_c('v-card',{attrs:{"color":"","flat":"","height":"100"}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 10,"md":"11","sm":"11"}},[_c('v-form',{ref:"chatForm",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-textarea',{attrs:{"label":"Type Something","placeholder":"Type Something","auto-grow":"","counter":200,"dense":"","outlined":"","rows":"1","row-height":"10","rules":_vm.rules.required},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 2,"md":"1","sm":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":function($event){return _vm.sendMessage()}}},[_vm._v("mdi-send")])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Sending Message "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }