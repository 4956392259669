<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    color="#26c6da"
    style="background-color: #dcdcdc"
    v-on:keydown.enter="login('firstTime')"
  >
    <v-row class="mt-6">
      <v-col md="4" sm="12" offset-md="4">
        <v-sheet elevation="2" class="pa-10">
          <v-row>
            <v-col cols="12" md="12">
              <v-card flat>
                <v-img
                  src="../../../assets/nhif-logo.png"
                  height="100"
                  contain
                  class="d-flex flex-row justify-center mt-n8"
                />
                <v-divider class="mt-n2" />
                <v-card-text class="text-justify mt-2 pb-0">
                  <div class="d-flex flex-row justify-center font-weight-black">
                    Linda Mama Registration
                  </div>
                </v-card-text>

                <v-card-actions class="">
                  <v-row> </v-row>
                </v-card-actions>
                <v-card-actions class="">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-btn
                        class="btn-success btn-block black--text"
                        color="#63c2de"
                        @click="dialog = true"
                      >
                        <v-icon small class="mt-2">mdi-account-plus</v-icon>
                        <span class="mt-2"> Normal Registration ?</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-btn class="btn-block black--text" text>
                        <span class="mt-1">or</span>
                      </v-btn>
                    </v-col>

                    <v-col cols="12" md="12" sm="12">
                      <v-btn
                        :to="{ name: 'LindaMamaHos' }"
                        class="btn-block black--text"
                        color="#ffc107"
                      >
                        <v-icon class="mt-1">mdi-office-building-marker</v-icon>
                        <span class="mt-1">Register by Hospital</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>

                <div class="alert text-muted mt-2">
                  For queries ,call NHIF Toll Free Line
                  <strong> 0800 720 601</strong>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent width="500">
      <v-card color="">
        <v-card-text class="d-flex flex-row justify-center"
          ><v-icon size="100" color="#FAD02C"
            >mdi-alert-circle</v-icon
          ></v-card-text
        >
        <v-card-text class="d-flex flex-row justify-center">
          <span class="text-subititle-2 font-weight-black"
            >Do you Have a National ID card ?</span
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="validateLindaDetails()"> Yes </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="addLindaDetails()"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { EventBus } from "@/utils/eventBus";
// import Spinner from "@/plugins/loader/views/Spinner";

export default {
  name: "lindaMamaChoice",
  //   components: { Spinner },
  data: function () {
    return {
      dialog: false,
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  mounted() {},

  computed: {},
  methods: {
    validateLindaDetails() {
      this.$router.push({ name: "LindaMamaSelfVal" });
      this.dialog = false;
    },
    addLindaDetails() {
      this.$router.push({ name: "LindaMamaGuardian" });
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");

.v-input__append-outer {
  margin-top: 8px;
}
</style>
