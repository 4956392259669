import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import 'vue-material-design-icons/styles.css';
// import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#202750",
        secondary: "#2ABCEF",
        drawerColor: "#171528",
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
