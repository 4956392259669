<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    color="#26c6da"
    style="background-color: #dcdcdc"
    v-on:keydown.enter="registerLindaMamaSelf()"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-sheet elevation="2" class="pa-10">
          <v-row>
            <v-col cols="12" md="12">
              <v-card flat>
                <v-alert
                  dense
                  v-if="$store.getters['Auth/alert'].status"
                  :type="
                    $store.getters['Auth/alert'].status === 'success'
                      ? 'success'
                      : 'error'
                  "
                  class="mb-10"
                >
                  {{ $store.getters["Auth/alert"].message }}
                </v-alert>
                <v-img
                  src="../../../assets/nhif-logo.png"
                  height="100"
                  contain
                  class="d-flex flex-row justify-center mt-n8"
                />
                <v-divider class="mt-n2" />

                <v-card-text class="text-justify mt-2 pb-0">
                  <div class="d-flex flex-row justify-center font-weight-black">
                    Linda Mama Self Registration
                  </div>

                  <v-form
                    v-model="isValid"
                    ref="lindaMamaForm"
                    class="mt-1"
                    v-on:submit.prevent
                  >
                    <v-text-field
                      dense
                      outlined
                      label="First Name"
                      placeholder="Enter your First Name"
                      v-model="formData.first_name"
                      ref="first_name"
                      :rules="rules.required"
                    />
                    <v-text-field
                      dense
                      outlined
                      label="Last Name"
                      placeholder="Enter your Last Name"
                      v-model="formData.last_name"
                      ref="last_name"
                      :rules="rules.required"
                    />
               
                    <!--Date of Birth-->

                    <v-row>
                      <v-col cols="12" md="12" sm="12">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="formData.dob"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              v-model="formData.dob"
                              dense
                              outlined
                              label="Birth Date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              :rules="rules.required"
                              v-bind="attrs"
                              v-on="on"
                            ></v-combobox>
                          </template>
                          <v-date-picker
                            v-model="formData.dob"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(formData.dob)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <!--date picker end-->
                    <v-text-field
                      dense
                      outlined
                      label=" National ID Number"
                      placeholder="Enter your ID Number"
                      v-model="formData.id_number"
                      ref="id_number"
                      :rules="rules.minLength"
                    />
                    <v-text-field
                      dense
                      outlined
                      label="Mobile Number"
                      placeholder="Enter your Mobile Number"
                      v-model="formData.contact_phone"
                      ref="phone"
                      :rules="rules.required"
                    />

                    <v-autocomplete
                      :items="
                        newCounties.length !== 0 ? newCounties : ['Loading']
                      "
                      v-model="formData.county_code"
                      outlined
                      dense
                      :item-value="(item) => item.county_id"
                      :item-text="(item) => item.county_name"
                      label="search your county"
                      :rules="rules.required"
                    ></v-autocomplete>


                    <v-text-field
                      dense
                      outlined
                      label="Email Address"
                      placeholder="Enter your Email Address (Optional)"
                      v-model="formData.contact_email"
                      ref="email"
                      :rules="rules.email"
                    />
                  </v-form>
                </v-card-text>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        class="btn-success btn-block white--text"
                        color="#4dbd74"
                        @click="registerLindaMamaSelf()"
                      >
                        <span>Submit</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>

                <div class="alert text-muted mt-n3">
                  Already Registered ?
                  <v-btn text class="blue--text" @click="redirect()"
                    >Login</v-btn
                  >
                </div>

                <div class="alert text-muted mt-n6">
                  For queries ,call NHIF Toll Free Line
                  <strong> 0800 720 601</strong>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Redirecting
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  name: "LindaMamaSelfVal",

  data: function () {
    return {
      isValid: false,
      dialog: false,
      dates: "",
      menu: false,
      formData: {
        first_name: "",
        last_name: "",
        dob: "",
        county_code: "",
        contact_phone: "",
        contact_email: "",
        id_number: "",
      },
      countyformData: {
        county: {},
      },
      newCounties: [],
      placeHolder: ["search your county"],
    };
  },

  computed: {
    rules() {
      return {
        email: [(v) => /.+@.+/.test(v) || "E-mail must be valid"],
        required: [(v) => !!v || "Field is required"],
        minLength: [
          (v) => !!v || "Field required",
        ],
      };
    },
    counties() {
      return this.$store.getters["Facilities/facilitiesGetters"]("counties");
    },

    checkPhoneNumber() {
      return this.formData.contact_phone !== "" ? true : false;
    },
  },

  methods: {
    registerLindaMamaSelf: function () {
      if (!this.isValid) {
        this.$refs.lindaMamaForm.validate();
      } else {
        this.$store.dispatch("Auth/selfRegisterLindaMama", this.formData);
      }
    },
    redirect() {
      this.$router.push({ name: "Login" });
    },
  },
  watch: {
    checkPhoneNumber: {
      handler: function () {
        if (this.checkPhoneNumber === true) {
          this.$store.dispatch("Facilities/getCounties", {
            source: "WEB",
            text: "",
            phone: this.formData.contact_phone,
            id: this.formData.id_number,
          });
        }
      },
    },
    counties: {
      handler: function () {
        if (this.counties.length > 0) {
          this.newCounties = [...this.counties];
        }
      },
    },
  },
 
};
</script>

<style scoped></style>
