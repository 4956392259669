<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="height: 100%; background-color: #eeeeee"
  >
    <v-card flat class="mx-auto">
      <v-card-title class="">
        <span class="title-font-style">NHIF Member Data Summary</span>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-row
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Member No
              </v-col>
              <v-col
                cols="8"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
              >
                {{ profile ? profile.member_number : "" }}
              </v-col>
            </v-row>
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n4'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                ID/Passport
              </v-col>
              <v-col
                cols="8"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
              >
                {{ profile ? profile.id_number : "" }}
              </v-col>
            </v-row>
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n4'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Name
              </v-col>
              <v-col
                cols="8"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
              >
                {{ profile ? profile.first_name : "" }} {{ "" }}
                {{ profile ? profile.last_name : "" }}
              </v-col>
            </v-row>
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n4'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Employer
              </v-col>
              <v-col
                cols="8"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
              >
                {{ profile ? profile.emp_name : "" }}
              </v-col>
            </v-row>
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n4'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Facility Name
              </v-col>
              <v-col
                cols="8"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
              >
                {{ memFacility ? memFacility.facility_name : "" }}
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical v-if="$vuetify.breakpoint.mdAndUp" />
          <v-col cols="12" md="6">
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n8'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Year of Birth
              </v-col>
              <v-col
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
                cols="8"
              >
                {{ profile ? profile.birthdate : "" }}
              </v-col>
            </v-row>
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n4'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Marital Status
              </v-col>
              <v-col
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
                cols="8"
              >
                {{ profile ? profile.marital_status : "" }}
              </v-col>
            </v-row>
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n4'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Mobile
              </v-col>
              <v-col
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
                cols="8"
              >
                {{ profile ? profile.phone : "" }}
              </v-col>
            </v-row>
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n3'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Status
              </v-col>
              <v-col cols="8">
                <v-chip
                  small
                  label
                  :class="
                    paymentStatus.payment_status === 'ACTIVE'
                      ? 'white--text'
                      : 'black--text'
                  "
                  :color="
                    paymentStatus
                      ? paymentStatus.payment_status === 'ACTIVE'
                        ? 'green'
                        : ''
                      : ''
                  "
                  >{{
                    paymentStatus ? paymentStatus.payment_status : ""
                  }}</v-chip
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat class="mt-8">
      <v-data-table
        :headers="headers"
        :items="dependants ? dependants : []"
        :items-per-page="5"
        class="elevation-0"
        :mobile-breakpoint="0"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="title-font-style"
              >Dependants</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:[`item.dependant_id`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{ item.dependant_id }}</span
          >
        </template>
        <template v-slot:[`item.birthdate`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{ item.birthdate }}</span
          >
        </template>

        <template v-slot:[`item.relationship`]="{ item }">
          <v-icon color="pink" v-if="item.gender === 'Female'"
            >mdi-human-female</v-icon
          >
          <v-icon color="blue" v-else>mdi-human-male</v-icon>
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
          >
            <span
              :class="item.gender === 'Female' ? 'pink--text' : 'blue--text'"
              >{{ item.relationship }}</span
            ></span
          >
        </template>
        <template v-slot:[`item.first_name`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
          >
            {{ item.last_name }} {{ "" }}
            {{ item.first_name }}
          </span>
      
        </template>
        <template v-slot:[`item.facility_name`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
          >
            {{ item.facility_name }} 
          </span>
        </template>
        <template v-slot:[`item.gender`]="{ item }">
          <v-icon color="pink" v-if="item.gender === 'Female'"
            >mdi-human-female</v-icon
          >
          <v-icon color="blue" v-else>mdi-human-male</v-icon>
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
          >
            <span
              :class="item.gender === 'Female' ? 'pink--text' : 'blue--text'"
              >{{ item.gender }}</span
            ></span
          >
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
          depressed
            small
            :disabled="profile.choose_facility === false"
            color="primary"
            :to="{
              name: 'dependantInfo',
              params: {
                code: encodeString(item.dependant_id),
                memberNo: encodeString(item.mem_no),
              },
            }"
          >
            <span
              :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
              >choose/change dependant facility</span
            >
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { AuthService } from "@/modules/auth";

export default {
  name: "profileSummary",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        id_number: AuthService.idNo,
        source: "WEB",
        type: "1",
      };
      const depData = {
        source: "WEB",
        id_number: AuthService.idNo,
        phone: AuthService.phone,
      };
      v.$store.dispatch("Profile/getProfile", data);
      v.$store.dispatch("Profile/getDependants", depData);
      v.$store.dispatch("Status/getPaymentStatus", {
        source: "WEB",
        phone: AuthService.phone,
        id_number: AuthService.idNo,
      });
    });
  },
  data() {
    return {
      headers: [
        {
          text: "Dep.No",
          align: "start",
          sortable: false,
          value: "dependant_id",
        },
        
        { text: "Relationship", value: "relationship" },
        { text: "D.O.B", value: "birthdate" },
        { text: "Name", value: "first_name" },
        { text: "Gender", value: "gender" },
        { text: "Facility", value: "facility_name" },
        { text: "View dependant", value: "actions" },
      ],
      isLoading: true,
    };
  },

  computed: {
    idNumber() {
      return AuthService.idNo;
    },
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
    dependants() {
      return this.$store.getters["Profile/profileGetters"]("dependants");
    },
    memFacility() {
      return this.$store.getters["Profile/profileGetters"]("facility");
    },
    paymentStatus() {
      return this.$store.getters["Status/statusGetters"]("paymentStatus");
    },
  

    
  },
  methods: {
    encodeString(val) {
      const encodedData = btoa(val);
      return encodedData;
    },
    makeEachLetterCaps(val) {
      const d = val.toLowerCase();
      const a = d.charAt(0).toUpperCase() + d.slice(1);
      return a;
    },
    firstLetter(val) {
      let text1 = val.last_name;
      let text2 = val.first_name;
      let result = text1.concat(" ", text2);
      var matches = result.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON

      // console.log(acronym);
      return acronym;
    },
  },
  watch: {
    profile: {
      handler: function () {
        if (this.profile) {
          this.$store.dispatch("Profile/getMemberFacility", {
            source: "WEB",
            member_no: this.profile.member_number,
            phone: AuthService.phone
          });
        }
      },
    },
  },
  mounted() {
    setInterval(() => {
      this.isLoading = false;
      // Set the loading prop to false once the data is loaded
    }, 3000);
  },
};
</script>

<style scoped>
@import url("../style.css");
</style>
