import { EventBus } from "@/utils/eventBus";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    names: {},
    alert: {
      status: "",
      message: "",
      user: {},
    },
  },
  mutations: {
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    alert: (state) => state.alert,
    payGetters: (state) => (val) => state[val],
  },
  actions: {
    payNhif: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      axios
        .post(
          "https://nhifpayments.tilil.co.ke/api_send_payment",
          data
        )
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc,
            });
            EventBus.$emit("openDialog", res.data.status_desc);
            EventBus.$emit("resetForm");
          } else {
            EventBus.$emit("closeSuccessDialog", res.data.status_desc);
            commit("SET_ALERT", {
              status: "error",
              message: "Something Went Wrong. Please Try again Later",
            });
            EventBus.$emit(
              "openDialog",
              "Something Went Wrong. Please Try again Later"
            );
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err,
          });
        });
    },

    getNames: ({ commit }, data) => {
      axios
        .post("https://nhifapi.tilil.co.ke/api_get_client_details", data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("MUTATE", { state: "names", value: res.data.data });
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc,
            });
            EventBus.$emit("closeDialog");
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
            EventBus.$emit("closeDialog");
          }
        })
        .catch(() => {
          commit("SET_ALERT", {
            status: "error",
            message: "Error retrieving Name",
          });
        });
    },
  },
};
