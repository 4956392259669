import routes from "./chatRoutes";
import store from "./chatStore";

export default {
  install(Vue, options) {
    options.router.addRoute("/", ...routes);
    options.store.registerModule("Chat", store);
  },
};

