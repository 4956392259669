<template>
  <v-overlay
    v-if="$store.getters['loading']"
    z-index="10"
    color="#000000"
    opacity=".4"
  >
    <spinner :animation-duration="800" :size="45" />
  </v-overlay>
</template>

<script>
import { AtomSpinner as spinner } from "epic-spinners";
export default {
  name: "Spinner",
  components: {
    spinner,
  },
};
</script>

<style scoped></style>
