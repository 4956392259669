<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="height: 100%; background-color: #eeeeee"
  >
    <v-card flat class="">
      <v-data-table
        :headers="headers"
        :items="contributions ? contributions : []"
        :items-per-page="15"
        class="elevation-0"
       
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.date`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{ item.date !== "" ? item.date : "N/A" }}</span
          >
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{
              item.amount !== "" ? item.amount : "N/A" | currencyFormat
            }}</span
          >
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="title-font-style"
              >Contributions</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:[`item.penalty`]="{}"
          ><span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >0</span
          >
        </template></v-data-table
      >
    </v-card>
  </v-container>
</template>

<script>
import { AuthService } from "@/modules/auth";
export default {
  name: "statusDashboard",
  components: {},

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        source: "WEB",
        phone: AuthService.phone,
        id_number: AuthService.idNo,
      };
      v.$store.dispatch("Status/getContributions", data);
    });
  },
  data() {
    return {
      headers: [
        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "date",
        },
        { text: "Amount (Ksh)", value: "amount" },
        { text: "Penalty", value: "penalty" },
      ],
    
    };
  },

  computed: {
    contributions() {
      return this.$store.getters["Status/statusGetters"]("contributions");
    },
  },
  methods: {},
  watch: {},
  
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
