var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"height":"100%","background-image":"linear-gradient(to bottom, #6f9787, #caeadd)"},attrs:{"fluid":"","color":"#26c6da"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit()}}},[_c('v-row',{},[(_vm.questions.length === 0)?_c('v-card',{staticClass:"mx-auto",attrs:{"width":"800","height":"800","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-skeleton-loader',{attrs:{"type":"card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line,list-item-three-line, list-item-three-line,list-item-three-line,list-item-three-line"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-skeleton-loader',{attrs:{"type":"card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"}})],1)],1)],1)],1):_vm._e(),(_vm.questions.length !== 0)?_c('v-card',{staticClass:"mx-auto",attrs:{"width":"800","height":"","flat":""}},[(_vm.$store.getters['Auth/alert'].status)?_c('v-alert',{staticClass:"mb-2 mt-3",attrs:{"dense":"","type":_vm.$store.getters['Auth/alert'].status === 'success'
            ? 'success'
            : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Auth/alert"].message)+" ")]):_vm._e(),_c('v-card-title',[_c('v-btn',{staticClass:"white--text",attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.redirect('login')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1),_c('v-spacer'),_c('span',{staticClass:"title-font-style"},[_vm._v(_vm._s(this.survey_name ? this.survey_name +" Questions" : "Survey Questions"))]),_c('v-spacer'),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"surveyForm",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'},[_vm._v(" Mobile")]),_c('v-text-field',{staticClass:"small-label",attrs:{"dense":"","outlined":"","label":"","placeholder":"","rules":_vm.rules.required,"type":"number"},model:{value:(_vm.allFormData.phone),callback:function ($$v) {_vm.$set(_vm.allFormData, "phone", $$v)},expression:"allFormData.phone"}}),_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'},[_vm._v(" ID Number")]),_c('v-text-field',{staticClass:"small-label",attrs:{"dense":"","outlined":"","label":"","placeholder":"","rules":_vm.rules.required},model:{value:(_vm.allFormData.id_number),callback:function ($$v) {_vm.$set(_vm.allFormData, "id_number", $$v)},expression:"allFormData.id_number"}}),_vm._l((_vm.questions),function(item,i){return _c('div',{key:i},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'},[_vm._v(" "+_vm._s(item.question))]),(item.options === '')?_c('v-text-field',{staticClass:"small-label",attrs:{"dense":"","outlined":"","label":"","placeholder":"","rules":item.validate_answer === '1' ? _vm.rules.required : []},model:{value:(_vm.formData[item.question_id]),callback:function ($$v) {_vm.$set(_vm.formData, item.question_id, $$v)},expression:"formData[item.question_id]"}}):_vm._e(),(item.options !== '')?_c('v-autocomplete',{attrs:{"items":item ? item.options.split(',') : [],"outlined":"","dense":"","rules":item.validate_answer === '1' ? _vm.rules.required : [],"item-value":(item) => item,"item-text":(item) => item,"label":""},model:{value:(_vm.formData[item.question_id]),callback:function ($$v) {_vm.$set(_vm.formData, item.question_id, $$v)},expression:"formData[item.question_id]"}}):_vm._e()],1)})],2)],1),_c('v-divider',{staticClass:"mt-n4"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-success white--text",attrs:{"depressed":"","width":"400","color":"#4dbd74"},on:{"click":function($event){return _vm.submit()}}},[_c('span',[_vm._v("Submit")])]),_c('v-spacer')],1)],1):_vm._e()],1),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","width":"600","height":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_c('v-icon',{attrs:{"size":"100","color":"success"}},[_vm._v("mdi-checkbox-marked-circle")])],1),_c('v-card-title',[_c('span',{staticClass:"title-font-style"},[_vm._v(_vm._s(_vm.text))])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"black"},on:{"click":function($event){return _vm.redirect('login')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Close ")],1),_c('v-spacer')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }