import constants from "./preauthConstants";
import call from "@/service/http";

export default {
  namespaced: true,
  state: {
    preauth: [],
   
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    preauthGetters: (state) => (val) => state[val],
  },

  actions: {
    getPreauth: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.PREAUTH, data)
        .then((res) => {
          commit("MUTATE", { state: "preauth", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
   
  },
};
