<template>
  <v-footer app :inset="true" elevation="0" class="overline" color="white">
    <span style="font-size: 0.625rem"> NHIF </span>

    <v-spacer />

    <v-btn x-small text href="" target="_blank"> </v-btn>
  </v-footer>
</template>

<script>
export default {
  name: "app-footer",
  props: {
    opacity: {
      type: String,
      default: "1",
    },
  },

  computed: {},
};
</script>

<style scoped></style>
