<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    color="#26c6da"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #6f9787, #caeadd);
    "
    v-on:keydown.enter="submit()"
  >
    <v-row class="">
      <v-card
        v-if="questions.length === 0"
        class="mx-auto"
        width="800"
        height="800"
        flat
      >
        <v-row>
          <v-col cols="12" md="8">
            <v-card flat>
              <v-skeleton-loader
                type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line,list-item-three-line, list-item-three-line,list-item-three-line,list-item-three-line"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card flat>
              <v-skeleton-loader
                type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        v-if="questions.length !== 0"
        class="mx-auto"
        width="800"
        height=""
        flat
      >
        <v-alert
          dense
          v-if="$store.getters['Auth/alert'].status"
          :type="
            $store.getters['Auth/alert'].status === 'success'
              ? 'success'
              : 'error'
          "
          class="mb-2 mt-3"
        >
          {{ $store.getters["Auth/alert"].message }}
        </v-alert>
        <v-card-title>
          <v-btn
            class="white--text"
            text
            color="red"
            @click="redirect('login')"
          >
            <v-icon>mdi-arrow-left</v-icon> Back
          </v-btn>
          <v-spacer />
          <span class="title-font-style">{{
            this.survey_name ? this.survey_name +" Questions" : "Survey Questions"
          }}</span>
          <v-spacer />
          <v-spacer />
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-form v-model="isValid" ref="surveyForm" v-on:submit.prevent>
            <!-- <span
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'
              "
            >
              Name</span
            >
            <v-text-field
              dense
              outlined
              label=" Name"
              placeholder="Enter Name"
              v-model="allFormData.name"
              :rules="rules.required"
              class="small-label"
            /> -->
            <span
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'
              "
            >
              Mobile</span
            >
            <v-text-field
              dense
              outlined
              label=""
              placeholder=""
              v-model="allFormData.phone"
              :rules="rules.required"
              class="small-label"
              type="number"
            />
            <span
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'
              "
            >
              ID Number</span
            >
            <v-text-field
              dense
              outlined
              label=""
              placeholder=""
              v-model="allFormData.id_number"
              :rules="rules.required"
              class="small-label"
            />
            <div v-for="(item, i) in questions" :key="i">
              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'text-overline new-main'
                    : 'mob-main-font-style'
                "
              >
                {{ item.question }}</span
              >
              <v-text-field
                v-if="item.options === ''"
                dense
                outlined
                label=""
                placeholder=""
                v-model="formData[item.question_id]"
                :rules="item.validate_answer === '1' ? rules.required : []"
                class="small-label"
              />
              <v-autocomplete
                v-if="item.options !== ''"
                :items="item ? item.options.split(',') : []"
                v-model="formData[item.question_id]"
                outlined
                dense
                :rules="item.validate_answer === '1' ? rules.required : []"
                :item-value="(item) => item"
                :item-text="(item) => item"
                label=""
              ></v-autocomplete>
            </div>
          </v-form>
        </v-card-text>
        <v-divider class="mt-n4" />

        <v-card-actions>
          <v-spacer />

          <v-btn
            depressed
            width="400"
            class="btn-success white--text"
            color="#4dbd74"
            @click="submit()"
          >
            <span>Submit</span> </v-btn
          ><v-spacer />
        </v-card-actions>
      </v-card>
    </v-row>

    <div class="text-center">
      <v-dialog persistent v-model="dialog" width="600" height="600">
        <v-card>
          <v-card-title class="justify-center">
            <v-icon size="100" color="success"
              >mdi-checkbox-marked-circle</v-icon
            >
          </v-card-title>

          <v-card-title>
            <span class="title-font-style">{{ text }}</span>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="white--text" color="black" @click="redirect('login')">
              <v-icon>mdi-arrow-left</v-icon> Close
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
//   import Spinner from "@/plugins/loader/views/Spinner";

export default {
  name: "survey",
  // components: { Spinner },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.query.id) {
        const data = {
          survey_id: v.$route.query.id,
        };
        v.$store.dispatch("Auth/getSurveyQuestions", data);
      }
    });
  },
  data: function () {
    return {
      text: "",
      dialog: false,
      isValid: false,
      allFormData: {
        phone: "",
        id_number: "",
        survey_id: "",
        answers: [],
      },
      formData: {},

      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  mounted() {
    EventBus.$on("successQuestions", (val) => {
      this.dialog = true;
      this.text = val;
      this.$toast.success(val);
    });
    EventBus.$on("error", (val) => {
      this.$toast.error(val);
    });
  },

  computed: {
    questions() {
      return this.$store.getters["Auth/authGetters"]("questions");
    },
    survey_name() {
      return this.$store.getters["Auth/authGetters"]("survey_name");
    },
    queryId() {
      return this.$route.query.id;
    },
  },
  methods: {
    redirect(val) {
      this.$router.push({ path: val });
    },

    removeAllExceptNames(arr, names) {
      arr.forEach((a) => {
        Object.keys(a).forEach((b) => {
          if (!names.includes(b)) {
            delete a[b];
          }
        });
      });
    },
    submit: function () {
      if (!this.isValid) {
        this.$refs.surveyForm.validate();
      } else {
        const keysArray = Object.keys(this.formData);

        this.questions.forEach((question) => {
          keysArray.forEach((key) => {
            if (question.question_id === key) {
              question.answer = this.formData[key];
              this.allFormData.survey_id = this.$route.query.id;
            }
          });
        });
        this.allFormData.answers.push(...this.questions);
        this.removeAllExceptNames(this.allFormData.answers, [
          "question_id",
          "answer",
        ]);

        this.$store.dispatch("Auth/submitQuestions", this.allFormData);
        this.$refs.surveyForm.reset();
      }
    },

    //
  },
};
</script>

<style scoped>
@import url("../styles.css");

.v-input__append-outer {
  margin-top: 8px;
}
</style>
