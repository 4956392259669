<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : true"
    style="height: 100%; background-color: #eeeeee"
  >
    <v-card class="mt-0" flat>
      <v-card-text class="card-text">
        <v-card color="" flat>
          <v-card color="" flat :loading="loading">
            <div class="d-flex flex-row mt-1">
              <div class="px-2 mt-0 mb-1">
                <v-avatar color="primary" class="white--text" size="46"
                  >NHIF</v-avatar
                >
                <span class="text-h6 ml-2 font-weight-bold">NHIF</span>
              </div>
              <div class="px-2 mt-3"></div>
            </div>
          </v-card>

          <v-list color="#f1f3f6" class="my-list" :v-scroll="onScroll">
            <v-list-item
              v-for="(item, i) in newChats
                ? newChats
                : ['Type Something Below']"
              :key="i"
            >
              <v-list-item-avatar
                :style="
                  item.direction === 1
                    ? ''
                    : 'position: absolute; top: 0; right: 0;'
                "
                color="primary"
                v-if="item.direction === 1"
              >
                <span class="white--text">NHIF</span>
              </v-list-item-avatar>

              <v-list-item-content
                :class="item.direction === 0 ? 'text-end' : 'text-start'"
              >
                <div
                  :class="
                    item.direction === 0 ? 'text-message' : 'nhif-message'
                  "
                  :style="
                    item.direction === 1
                      ? ''
                      : 'position: absolute; top: 0; right: 0;'
                  "
                >
                  {{ item.text }}

                  <span
                    class="mt-1 ml-2 mr-0 green--text"
                    v-if="item.direction === 0"
                    ><v-icon x-small color="green">{{
                      item.query_status === 0 ? "mdi-check" : "mdi-check-all"
                    }}</v-icon
                    >{{ item.query_status === 0 ? "sent" : "seen" }}</span
                  >
                </div>

                <span
                  class="chat-font-style"
                  :style="
                    item.direction === 1 ? 'margin-top:5px' : 'margin-top:40px;'
                  "
                >
                  {{ formatQueryTime(item.createdon) }}
                </span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-action>
              <v-btn v-show="showButton" fab bottom right color="primary">
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list>

          <v-card color="" flat height="100">
            <v-row>
              <v-col
                :cols="$vuetify.breakpoint.mdAndUp ? 12 : 10"
                md="11"
                sm="11"
                class=""
                ><v-form v-model="isValid" ref="chatForm" v-on:submit.prevent>
                  <v-textarea
                    label="Type Something"
                    placeholder="Type Something"
                    auto-grow
                    :counter="200"
                    dense
                    outlined
                    rows="1"
                    row-height="10"
                    v-model="question"
                    :rules="rules.required"
                  ></v-textarea> </v-form
              ></v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? 12 : 2" md="1" sm="1"
                ><v-icon large @click="sendMessage()">mdi-send</v-icon></v-col
              >
            </v-row>
          </v-card>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Sending Message
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { AuthService } from "@/modules/auth";
import { EventBus } from "@/utils/eventBus";
export default {
  name: "chat",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Chat/getChats", {
        phone: AuthService.phone,
        source: "WEB",
      });
    });
  },
  data: function () {
    return {
      question: "",
      newChats: [],
      isValid: false,
      dialog: false,
      dialogMessage: false,
      rules: {
        required: [
          (value) => !!value || "Message Cannot be Empty.",
          (v) =>
            (v || "").length <= 200 ||
            "Description must be 200 characters or less",
        ],
      },
      loading: true,

      scrollTop: 0,
      showButton: false,
    };
  },

  computed: {
    chats() {
      return this.$store.getters["Chat/chatGetters"]("chats");
    },
  },

  methods: {
    sendMessage() {
      if (!this.isValid) {
        this.$refs.chatForm.validate();
      } else {
        this.dialog = true;
        const data = {
          phone: AuthService.phone,
          source: "WEB",
          id_number: AuthService.idNo,
          query: this.question,
        };
        this.$store.dispatch("Chat/sendQuery", data);
      }
    },
    formatQueryTime(rawUnixTime) {
      const unixTime = parseInt(rawUnixTime);
      const date = new Date(unixTime * 1000);

      let formattedTime;
      let beautifiedDate = null;

      const diffSeconds = Math.floor((Date.now() - unixTime * 1000) / 1000);
      const diffMinutes = Math.floor(diffSeconds / 60);

      if (diffSeconds <= 59) {
        formattedTime = "Just now";
        beautifiedDate = formattedTime;
      } else if (diffMinutes <= 59) {
        formattedTime = diffMinutes + " min ago";
        beautifiedDate = formattedTime;
      } else {
        const today = new Date();
        const currentDay = today.getDate();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        const messageDay = date.getDate();
        const messageMonth = date.getMonth();
        const messageYear = date.getFullYear();

        const dateFormat = new Intl.DateTimeFormat("default", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });

        if (
          currentDay === messageDay &&
          currentMonth === messageMonth &&
          currentYear === messageYear
        ) {
          // The message was sent today
          formattedTime = dateFormat.format(date);
          beautifiedDate = formattedTime;
        } else if (
          currentDay - messageDay === 1 &&
          currentMonth === messageMonth &&
          currentYear === messageYear
        ) {
          // The message was sent yesterday
          formattedTime = dateFormat.format(date);
          beautifiedDate = `Yesterday at ${formattedTime}`;
        } else if (currentYear - messageYear === 1) {
          // The message was sent in a previous year
          const longDateFormat = new Intl.DateTimeFormat("default", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          formattedTime = longDateFormat.format(date);
          beautifiedDate = formattedTime;
        } else {
          // The message was sent in the current year or earlier
          const shortDateFormat = new Intl.DateTimeFormat("default", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          formattedTime = shortDateFormat.format(date);
          beautifiedDate = formattedTime;
        }
      }

      return beautifiedDate;
    },
    scrollToBottom() {
      const list = document.querySelector(".my-list");
      list.scrollTop = list.scrollHeight;
    },
    // onScroll(e) {
    //   const currentPosition = e.target.scrollTop;
    //   if (currentPosition < this.lastScrollPosition) {
    //     this.showButton = true;
    //   } else {
    //     this.showButton = false;
    //   }
    //   this.lastScrollPosition = currentPosition;
    // },
    onScroll(event) {
      console.log(event);
      this.scrollTop = event.target.scrollTop;
      this.showButton = this.scrollTop > 0;
    },
    scrollToTop() {
      const list = document.querySelector(".my-list");
      list.scrollTop = list.scrollHeight;
    },
  },
  mounted() {
    EventBus.$on("closeDialog", () => {
      this.dialog = false;
      this.$refs.chatForm.reset();
    });
    EventBus.$on("dialogMessage", () => {
      this.$toast.error("Failed Try Again Later");
      this.dialog = false;
      this.$refs.chatForm.reset();
    });

    setInterval(() => {
      this.loading = false;
    }, 1000);
    setInterval(() => {
      this.loading = false;

      // Set the loading prop to false once the data is loaded
      this.$store.dispatch("Chat/getChats", {
        phone: AuthService.phone,
        source: "WEB",
      });
    }, 60000);

    window.scrollTo(0, document.body.scrollHeight);
  },
  watch: {
    chats: {
      handler: function () {
        if (this.chats.length > 0) {
          this.newChats = [...this.chats];
        }
      },
    },
    loading: {
      handler: function () {
        if (this.loading === false && this.chats.length > 0) {
          setInterval(() => {
            this.scrollToBottom();
          }, 5000);
        }
      },
    },
  },
};
</script>
<style>
.text-message {
  color: #fff;
  background-color: #202750;
  border-color: #202750;
  position: relative;
  padding: 0.75rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  max-width: fit-content;
  margin-right: 10px;
  border-radius: 0px 15px 0px 0px;
}

.nhif-message {
  color: #171528;
  background-color: #fff;
  border-color: #fff;

  position: relative;
  padding: 0.75rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  max-width: fit-content;
  border-radius: 0px 0px 0px 15px;
}

.message {
  align-items: flex-end;
}

.v-list {
  height: 640px; /* or any height you want */
  overflow-y: auto;
}

.chat-font-style {
  font-size: 12px;
  --font-family-sans-serif: ("DM Sans", sans-serif), "Helvetica Neue", Arial,
    "Noto Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
</style>
