import dashboard from "@/modules/dashboard/views/dashboard";
import statusDashboard from "@/modules/status/components/statusDashboard";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/contributions",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "statusDashboard",
        component: statusDashboard,
        meta: { middleware: [Auth]},
      },
    ],
  },
];

