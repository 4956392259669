<template>
  <v-container
    style="background-color: #eeeeee"
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
  >
    <v-alert text class="mt-2" color="teal" icon="mdi-clock-fast" border="left">
      <span class="dash-font-style"
        >Hello {{ profile ? profile.first_name : "" }} {{ "" }}
        {{ profile ? profile.last_name : "" }} !</span
      >
    </v-alert>

    <v-alert
      text
      class="mt-n2"
      :color="
        paymentStatus.payment_desc === 'Payment is upto date' ? 'teal' : 'red'
      "
      :icon="
        paymentStatus.payment_desc === 'Payment is upto date'
          ? 'mdi-emoticon-happy'
          : 'mdi-emoticon-sad'
      "
    >
      <span
        :class="
          paymentStatus.payment_desc === 'Payment is upto date'
            ? 'dash-font-style'
            : 'dash-font-style red--text'
        "
        >{{ paymentStatus.payment_desc }} !</span
      >
    </v-alert>

    <v-row align="center" justify="center">
      <!--Dependants-->
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          flat
          class=""
          color="#4dbd74"
          dark
          :height="$vuetify.breakpoint.mdAndUp ? 140 : 100"
        >
          <v-row
            ><v-col cols="12" md="6" sm="12" justify="center">
              <v-row>
                <v-card-text
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'text-h4 font-weight-bold mt-0 ml-2'
                      : 'text-h5 font-weight-bold mt-0 ml-2'
                  "
                >
                  {{
                    dependants && dependants.length !== 0
                      ? dependants.length
                      : "N/A"
                  }}
                  <v-icon
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    size="30"
                    class="ml-8"
                    >mdi-account-multiple</v-icon
                  >
                </v-card-text>
                <v-card-text class="mt-n4 ml-2">
                  <div
                    class="text-subtitle-1 font-weight-bold"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    Dependants
                  </div>
                  <div
                    class="dash-font-style font-weight-bold mt-n3"
                    v-if="!$vuetify.breakpoint.mdAndUp"
                  >
                    Dependants
                  </div>
                </v-card-text>
              </v-row>
            </v-col>
            <v-col cols="12" md="1" sm="12"></v-col>

            <v-col
              cols="12"
              md="5"
              sm="12"
              class="align-end"
              v-if="$vuetify.breakpoint.mdAndUp"
              ><v-icon size="60">mdi-account-multiple</v-icon></v-col
            ></v-row
          >
        </v-card>
      </v-col>
      <!--Penalties-->
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          flat
          class=""
          color="#63c2de"
          dark
          :height="$vuetify.breakpoint.mdAndUp ? 140 : 100"
        >
          <v-row
            ><v-col cols="12" md="6" sm="12" justify="center">
              <v-row>
                <v-card-text
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'text-h4 font-weight-bold mt-0 ml-2'
                      : 'text-h5 font-weight-bold mt-0 ml-2'
                  "
                >
                  {{
                    paymentStatus.penalties !== undefined
                      ? paymentStatus.penalties
                      : "0"
                  }}
                  <v-icon
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    size="30"
                    class="ml-8"
                    >mdi-gavel</v-icon
                  >
                </v-card-text>
                <v-card-text class="mt-n4 ml-2">
                  <div
                    class="text-subtitle-1 font-weight-bold"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    Penalties
                  </div>
                  <div
                    class="dash-font-style font-weight-bold mt-n3"
                    v-if="!$vuetify.breakpoint.mdAndUp"
                  >
                    Penalties
                  </div>
                </v-card-text>
              </v-row>
            </v-col>
            <v-col cols="12" md="1" sm="12"></v-col>

            <v-col
              cols="12"
              md="5"
              sm="12"
              class="align-end"
              v-if="$vuetify.breakpoint.mdAndUp"
              ><v-icon size="60">mdi-gavel</v-icon></v-col
            ></v-row
          >
        </v-card>
      </v-col>

      <!--Arrears-->
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          flat
          class=""
          color="#ffc107"
          dark
          :height="$vuetify.breakpoint.mdAndUp ? 140 : 100"
        >
          <v-row
            ><v-col cols="12" md="6" sm="12" justify="center">
              <v-row>
                <v-card-text
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'text-h4 font-weight-bold mt-0 ml-2'
                      : 'text-h5 font-weight-bold mt-0 ml-2'
                  "
                >
                  {{
                    paymentStatus.arreas !== undefined &&
                    paymentStatus.arreas !== 0
                      ? paymentStatus.arreas
                      : "N/A"
                  }}
                  <v-icon
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    size="30"
                    class="ml-8"
                    >mdi-cash</v-icon
                  >
                </v-card-text>
                <v-card-text class="mt-n4 ml-2">
                  <div
                    class="text-subtitle-1 font-weight-bold"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    Arrears
                  </div>
                  <div
                    class="dash-font-style font-weight-bold mt-n3"
                    v-if="!$vuetify.breakpoint.mdAndUp"
                  >
                    Arrears
                  </div>
                </v-card-text>
              </v-row>
            </v-col>
            <v-col cols="12" md="1" sm="12"></v-col>

            <v-col
              cols="12"
              md="5"
              sm="12"
              class="align-end"
              v-if="$vuetify.breakpoint.mdAndUp"
              ><v-icon size="60">mdi-cash</v-icon></v-col
            ></v-row
          >
        </v-card>
      </v-col>

      <!--Contributions-->

      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          flat
          class=""
          color="#f86c6b"
          dark
          :height="$vuetify.breakpoint.mdAndUp ? 140 : 100"
        >
          <v-row
            ><v-col cols="12" md="6" sm="12" justify="center">
              <v-row>
                <v-card-text
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'text-h4 font-weight-bold mt-0 ml-2'
                      : 'text-h5 font-weight-bold mt-0 ml-2'
                  "
                >
                  {{
                    paymentStatus.last_contribution !== undefined &&
                    paymentStatus.last_contribution !== 0
                      ? paymentStatus.last_contribution
                      : "yy-mm"
                  }}
                  <v-icon
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    size="30"
                    class="ml-4"
                    >mdi-database</v-icon
                  >
                </v-card-text>
                <v-card-text class="mt-n4 ml-2">
                  <div
                    class="cont-font-style font-weight-bold"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    Last Contribution
                  </div>
                  <div
                    class="dash-font-style font-weight-bold mt-n3"
                    v-if="!$vuetify.breakpoint.mdAndUp"
                  >
                    Last Contribution
                  </div>
                </v-card-text>
              </v-row>
            </v-col>
            <v-col cols="12" md="1" sm="12"></v-col>

            <v-col
              cols="12"
              md="5"
              sm="12"
              class="align-end"
              v-if="$vuetify.breakpoint.mdAndUp"
              ><v-icon size="60">mdi-database</v-icon></v-col
            ></v-row
          >
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col
        cols="12"
        md="3"
        align="center"
        justify="center"
        :class="$vuetify.breakpoint.mdAndUp ? 'mt-3' : 'mt-n3'"
      >
        <v-card
          class="mx-auto"
          outlined
          :height="$vuetify.breakpoint.mdAndUp ? 220 : 200"
        >
          <v-card-title
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'card-header-three justify-center'
                : 'mob-card-header-three justify-center'
            "
            >Benefits of NHIF</v-card-title
          >

          <v-card-text>
            <div class="dash-font-style mt-3">
              Upon admission in hospital an NHIF member is accorded services and
              the hospital makes a claim to the Fund for reimbursement. An
              in-patient cover for the contributor, declared spouse and
              children.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
        align="center"
        justify="center"
        :class="$vuetify.breakpoint.mdAndUp ? 'mt-3' : 'mt-n3'"
      >
        <v-card class="mx-auto" outlined height="230">
          <v-card-title
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'card-header-three justify-center'
                : 'mob-card-header-three justify-center'
            "
            >NHIF Registration</v-card-title
          >

          <v-card-text>
            <div class="dash-font-style mt-3">
              NHIF registers all eligible members from both the formal and
              informal sector. For those in the formal sector, it is compulsory
              to be a member. For those in the informal sector and retirees,
              membership is open and voluntary.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
        align="center"
        justify="center"
        :class="$vuetify.breakpoint.mdAndUp ? 'mt-3' : 'mt-n3'"
      >
        <v-card class="mx-auto" outlined height="230">
          <v-card-title
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'card-header-three justify-center'
                : 'mob-card-header-three justify-center'
            "
            >Are You Registered</v-card-title
          >

          <v-card-text>
            <div class="dash-font-style mt-3">
              Under the law, all persons resident in Kenya, over 18 years and
              whose total income is not less than Kshs. 1,000 in any given month
              are required to contribute to the Fund irrespective of whether the
              spouse is a contributor or not.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
        align="center"
        justify="center"
        :class="$vuetify.breakpoint.mdAndUp ? 'mt-3' : 'mt-n3'"
      >
        <v-card class="mx-auto" outlined height="220">
          <v-card-title
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'card-header-three justify-center'
                : 'mob-card-header-three justify-center'
            "
            >Access Using USSD</v-card-title
          >

          <v-card-text>
            <div class="dash-font-style mt-3">
              The NHIF USSD allows you to access various NHIF selfcare services.
              For new members , you can dial *155# on any phone toll free and
              register as an NHIF member.
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" sm="12" class="mt-3 mb-2">
        <v-card class="mx-auto overflow-hidden" outlined color="" height="280">
          <v-card-title class="justify-center mt-2">Quick Links</v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-row
                  ><v-col class="dash-font-style"
                    ><v-icon>mdi-email</v-icon> P.O. Box: 30443 - 00100,
                    Nairobi, Kenya.
                  </v-col>
                </v-row>
                <v-row
                  ><v-col class="dash-font-style"
                    ><v-icon>mdi-cellphone</v-icon> Phone: 0800 720 601, (020)
                    2723255/56
                  </v-col>
                </v-row>
                <v-row
                  ><v-col class="dash-font-style"
                    ><v-icon>mdi-cellphone</v-icon> (020) 2714793/94, (020)
                    2722527
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="4">
                <v-row
                  ><v-col class="dash-font-style"
                    ><v-icon>mdi-pencil</v-icon> Complaints:
                    <span style="color: #2baab6">complaints@nhif.or.ke</span>
                  </v-col>
                </v-row>
                <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n2'"
                  ><v-col class="dash-font-style"
                    ><v-icon>mdi-pencil</v-icon> Enquiries:<span
                      style="color: #2baab6"
                      >customercare@nhif.or.ke</span
                    >
                  </v-col>
                </v-row>

                <v-row
                  ><v-col class="dash-font-style"
                    ><v-icon>mdi-pencil</v-icon> Liase:
                    <span style="color: #2baab6"> info@nhif.or.ke</span></v-col
                  >
                </v-row>
              </v-col>

              <v-col cols="12" md="4">
                <v-row
                  ><v-col class="dash-font-style"
                    ><v-icon>mdi-facebook</v-icon>
                    <span
                      style="color: #2baab6; text-decoration: underline"
                      onclick="window.open('https://www.facebook.com/nhifkenya/')"
                      >Facebook
                    </span>
                  </v-col>
                </v-row>
                <v-row
                  ><v-col class="dash-font-style"
                    ><v-icon>mdi-twitter</v-icon>
                    <span
                      style="color: #2baab6; text-decoration: underline"
                      onclick="window.open('https://twitter.com/nhifkenya')"
                      >Twitter</span
                    >
                  </v-col>
                </v-row>
                <v-row
                  ><v-col class="dash-font-style"
                    ><v-icon>mdi-earth</v-icon>
                    <span
                      style="color: #2baab6; text-decoration: underline"
                      onclick="window.open('https://registeronline.nhif.or.ke/member/')"
                    >
                      Website</span
                    ></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AuthService } from "@/modules/auth";
export default {
  name: "Dashboard",

  data: () => ({}),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        source: "WEB",
        phone: AuthService.phone,
        id_number: AuthService.idNo,
      };
      const profData = {
        id_number: AuthService.idNo,
        source: "WEB",
        type: "1",
      };

      v.$store.dispatch("Status/getPaymentStatus", data);
      v.$store.dispatch("Profile/getDependants", data);
      v.$store.dispatch("Profile/getProfile", profData);
    });
  },

  computed: {
    paymentStatus() {
      return this.$store.getters["Status/statusGetters"]("paymentStatus");
    },
    dependants() {
      return this.$store.getters["Profile/profileGetters"]("dependants");
    },
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
  },
  watch: {
    profile: {
      handler: function () {
        if (this.profile) {
          this.$store.dispatch("Profile/getMemberFacility", {
            source: "WEB",
            member_no: this.profile.member_number,
            phone: AuthService.phone,
          });
        }
      },
    },
  },

  methods: {
    redirect(val) {
      this.$router.push({ path: val });
    },
  },
};
</script>

<style scoped>
.card-header-three {
  color: #000;
  background-color: #63c2de;
  font-size: 15px;
}

.dash-font-style {
  font-size: 13px;
  --font-family-sans-serif: ("DM Sans", sans-serif), "Helvetica Neue", Arial,
    "Noto Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.mob-card-header-three {
  color: #000;
  background-color: #63c2de;
  font-size: 13px;
}
.mob-font-style {
  font-size: 10px;
  --font-family-sans-serif: ("DM Sans", sans-serif), "Helvetica Neue", Arial,
    "Noto Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
.cont-font-style {
  font-size: 15px;
  --font-family-sans-serif: ("DM Sans", sans-serif), "Helvetica Neue", Arial,
    "Noto Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
.status-font-style {
  font-size: 13px;
  --font-family-sans-serif: ("DM Sans", sans-serif), "Helvetica Neue", Arial,
    "Noto Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
</style>
