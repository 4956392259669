import { AuthService } from "@/modules/auth";

let timeoutId;
const timeoutInMS =  180000;

function handleInactive() {
  if (AuthService.check()) {
    AuthService.timerLogout();
  }
}

function startTimer() {
  timeoutId = setTimeout(handleInactive, timeoutInMS);
}

function resetTimer() {
  clearTimeout(timeoutId);
  startTimer();
}

export function setupTimers() {
  document.addEventListener("keypress", resetTimer, false);
  document.addEventListener("mousemove", resetTimer, false);
  document.addEventListener("mousedown", resetTimer, false);
  document.addEventListener("touchmove", resetTimer, false);

  startTimer();
}
