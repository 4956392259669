<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="height: 100%; background-color: #dcdcdc"
  >
    <v-card class="mx-auto">
      <v-card-title>
        <v-btn color="primary" text :to="{ name: 'profileSummary' }">
          <v-icon>mdi-arrow-left</v-icon>
          <span>Back</span>
        </v-btn>
      </v-card-title>
      <v-card-title>
        <span class="title-font-style">Dependant Info</span>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-card outlined class="mt-4 px-3">
          <v-row>
            <v-col cols="12" md="4" sm="12" class="mt-4">
              <v-row>
                <v-col
                  cols="4"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'main-font-style'
                      : 'mob-main-font-style'
                  "
                >
                  <span> Member Number</span>
                </v-col>
                <v-divider
                  vertical
                  class="mb-3"
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
                <v-col
                  cols="8"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'font-style'
                      : 'mob-font-style'
                  "
                >
                  {{ dependant ? dependant.mem_no : "" }}
                </v-col>
                <v-divider
                  class="mb-3"
                  vertical
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
              </v-row>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="mt-4">
              <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n10'"
                ><v-col
                  cols="4"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'main-font-style'
                      : 'mob-main-font-style'
                  "
                >
                  Member Name
                </v-col>
                <v-divider
                  vertical
                  class="mb-3"
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
                <v-col
                  cols="8"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'font-style'
                      : 'mob-font-style'
                  "
                >
                  {{ dependant ? dependant.first_name : "" }} {{ "" }}
                  {{ dependant ? dependant.last_name : "" }}
                </v-col>
                <v-divider
                  vertical
                  class="mb-3"
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
              </v-row>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="mt-4">
              <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n10'"
                ><v-col
                  cols="4"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'main-font-style'
                      : 'mob-main-font-style'
                  "
                >
                  Relationship
                </v-col>
                <v-divider
                  vertical
                  class="mb-3"
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
                <v-col
                  cols="8"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'font-style'
                      : 'mob-font-style'
                  "
                >
                  {{ dependant ? dependant.relationship : "" }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider v-if="$vuetify.breakpoint.mdAndUp" />
          <v-row>
            <v-col cols="12" md="4" sm="12" class="mt-4">
              <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n10'">
                <v-col
                  cols="4"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'main-font-style'
                      : 'mob-main-font-style'
                  "
                >
                  <span>Gender</span>
                </v-col>
                <v-divider
                  vertical
                  class="mb-3"
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
                <v-col
                  cols="8"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'font-style'
                      : 'mob-font-style'
                  "
                >
                  <v-icon color="pink" v-if="dependant.gender === 'Female'"
                    >mdi-human-female</v-icon
                  >
                  <v-icon color="blue" v-else>mdi-human-male</v-icon>
                  {{ dependant !== undefined ? dependant.gender : "" }}
                </v-col>
                <v-divider
                  class="mb-3"
                  vertical
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
              </v-row>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="mt-4">
              <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n10'"
                ><v-col
                  cols="4"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'main-font-style'
                      : 'mob-main-font-style'
                  "
                >
                  Facility Name
                </v-col>
                <v-divider
                  vertical
                  class="mb-3"
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
                <v-col
                  cols="8"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'font-style'
                      : 'mob-font-style'
                  "
                >
                  {{ dependant ? dependant.facility_name : "None Chosen" }}
                </v-col>
                <v-divider
                  vertical
                  class="mb-3"
                  v-if="$vuetify.breakpoint.mdAndUp"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>

      <v-card-text class="mt-3">
        <span class="title-font-style">Change Dependant Facility</span>
        <v-form v-model="isValid" ref="facilityForm" v-on:submit.prevent>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                  sm="12"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'main-font-style'
                      : 'mob-main-font-style'
                  "
                  >County</v-col
                >
                <v-col
                  cols="12"
                  md="10"
                  sm="12"
                  :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n4'"
                >
                  <v-autocomplete
                    :items="
                      newCounties.length !== 0
                        ? newCounties
                        : ['search your county']
                    "
                    v-model="formData.county"
                    outlined
                    dense
                    :item-value="(item) => item"
                    :item-text="(item) => item.county_name"
                    label="search your county"
                    :rules="rules.required"
                    @change="getFacilities()"
                  ></v-autocomplete>
                </v-col> </v-row
            ></v-col>

            <v-col cols="12" md="12" sm="12" class="mt-n6">
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                  sm="12"
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'main-font-style'
                      : 'mob-main-font-style'
                  "
                  >Preffered Facility</v-col
                >
                <v-col cols="12" md="10" sm="12">
                  <v-autocomplete
                    v-model="formData.facility"
                    :items="
                      newFacilities.length !== 0
                        ? newFacilities
                        : Check
                        ? ['No Facilities found']
                        : ['loading']
                    "
                    outlined
                    dense
                    :item-value="(item) => item"
                    :item-text="(item) => item.facility_name"
                    label=""
                    :rules="rules.required"
                  ></v-autocomplete>
                </v-col> </v-row
            ></v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="">
        <v-spacer />

        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          color="secondary white--text"
          @click="save()"
          :disabled="isDisabled"
        >
          <span>save Dependant Facility</span>
        </v-btn>
        <v-btn
        v-if="!$vuetify.breakpoint.mdAndUp"
          small
          color="secondary white--text"
          @click="save()"
          :disabled="isDisabled"
        >
          <span>save Dependant Facility</span>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <div class="text-center">
      <v-dialog persistent v-model="ratingDialog" width="400" height="600">
        <v-card>
          <v-card-title>
            <v-spacer /><v-icon
              size="30"
              color="red"
              @click="ratingDialog = false"
              >mdi-close</v-icon
            ></v-card-title
          >
          <v-card-text class="rating-text mt-3">
            <div class="text-center">
              {{ profile ? profile.rating_message : "" }}
            </div>
          </v-card-text>
          <v-card-text>
            <div class="text-center">
              <v-rating
                v-model="ratingForm.rating"
                color="yellow darken-3"
                background-color="yellow darken-3"
                size="40"
                half-increments
                hover
              ></v-rating>
            </div>
          </v-card-text>
          <v-card-text>
            <v-textarea
              dense
              outlined
              label="FeedBack(Optional)"
              placeholder="FeedBack(Optional)"
              v-model="ratingForm.feedback"
              ref="question"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="white--text mt-n3"
              color="primary"
              @click="submitRating()"
            >
              save
            </v-btn>
            <v-spacer />
          </v-card-actions>
          <v-dialog v-model="linearDialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                submitting
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar">
        Thanks for your feedback

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import { AuthService } from "@/modules/auth";
import { EventBus } from "@/utils/eventBus";
export default {
  name: "dependantInfo",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        member_no: atob(v.$route.params.memberNo),
        dependant_id: atob(v.$route.params.code),
        source: "WEB",
      };
      v.$store.dispatch("Profile/getOneDependant", data);

      v.$store.dispatch("Profile/getProfile", {
        id_number: AuthService.idNo,
        source: "WEB",
        type: "1",
      });
      v.$store.dispatch("Facilities/getCounties", {
        source: "WEB",
        text: "",
        phone: AuthService.phone,
        id_number: AuthService.idNo,
      });
    });
  },
  data: function () {
    return {
      isDepForm: false,
      newCounties: [],
      newFacilities: [],
      formData: {
        county: {},
        facility: {},
      },
      ratingDialog: false,
      ratingForm: {
        feedback: "",
        rating: 0,
        source: "WEB",
        member_no: "",
      },
      snackbar: false,
      linearDialog: false,
      isValid: false,
      Check: false,
    };
  },

  computed: {
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
    dependant() {
      return this.$store.getters["Profile/profileGetters"]("dependant");
    },
    counties() {
      return this.$store.getters["Facilities/facilitiesGetters"]("counties");
    },
    facilities() {
      return this.$store.getters["Facilities/facilitiesGetters"]("facilities");
    },
    memberNo() {
      return atob(this.$route.params.memberNo);
    },
    depNo() {
      return atob(this.$route.params.code);
    },
    isDisabled() {
      return Object.keys(this.formData.facility).length === 0 ? true : false;
    },
    isNull() {
      return this.formData.facility === null ? true : false;
    },
    rules() {
      return {
        required: [(v) => !!v || "Field is required"],
      };
    },
  },
  methods: {
    getFacilities() {
      this.$store.dispatch("Facilities/getFacilities", {
        source: "WEB",
        phone: AuthService.phone,
        id: AuthService.idNo,
        facility_name: "",
        county_name: this.formData.county.county_name,
        county_code: this.formData.county.county_id,
      });
    },
    save() {
      if (!this.isValid) {
        this.$refs.facilityForm.validate();
      } else {
        const data = {
          allData: {
            facility_no: this.formData.facility.hospital_id,
            mem_no: this.memberNo,
            dependant_no: this.depNo,
            source: "WEB",
          },
          rating: this.profile.rating,
        };
      
        this.$store.dispatch("Profile/saveDependantFacility", data);
      }
    },
    submitRating() {
      this.linearDialog = true;
      this.ratingForm.member_no = this.profile.member_number;
      this.$store.dispatch("Facilities/submitRating", this.ratingForm);
    },
  },
  watch: {
    counties: {
      handler: function () {
        if (this.counties.length > 0) {
          this.newCounties = [...this.counties];
        }
      },
    },
    facilities: {
      handler: function () {
        if (this.facilities.length > 0) {
          this.newFacilities = [...this.facilities];
        }
        if (this.facilities.length > 0) {
          this.Check = false;
        } else {
          this.Check = true;
        }
      },
    },
  },
  mounted() {
    EventBus.$on("changeDependant", () => {
      this.$toast.success("Dependant Facility Changed Successfully");
      this.formData.county = {};
      this.formData.facility = {};
    });
    EventBus.$on("openRatingBox", () => {
      this.ratingDialog = true;
    });
    EventBus.$on("openSnackBar", () => {
      this.linearDialog = false;
      this.ratingDialog = false;
      setTimeout(() => {
        this.snackbar = true;
      }, 2000);
    });
    EventBus.$on("closeDialog", () => {
      this.linearDialog = false;
      this.ratingDialog = false;
    });
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
