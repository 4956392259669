import constants from "./statusConstants";
import call from "@/service/http";

export default {
  namespaced: true,
  state: {
    contributions: [],
    paymentStatus: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    statusGetters: (state) => (val) => state[val],
  },

  actions: {
    getContributions: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.contributions, data)
        .then((res) => {
          commit("MUTATE", { state: "contributions", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    getPaymentStatus: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.PAYMENTSTATUS, data)
        .then((res) => {
          commit("MUTATE", { state: "paymentStatus", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          // commit("SET_LOADING", false, {  root: true });
        });
    },
  },
};
