<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="height: 100%; background-color: #dcdcdc"
    class=""
  >
    <v-card flat class="mx-auto">
      <v-card-title>
        <span class="title-font-style">Choice of Facility</span>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="12" class="px-3">
            <v-row
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Member Number
              </v-col>
              <v-col
                cols="8"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
              >
                {{ profile ? profile.member_number : "" }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="px-3">
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n8'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Member Name
              </v-col>
              <v-col
                cols="8"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
              >
                {{ profile ? profile.first_name : "" }}{{ "" }}
                {{ profile ? profile.last_name : "" }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="px-3">
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n8'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Relationship
              </v-col>
              <v-col
                cols="8"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
              >
                SELF
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="px-3">
            <v-row :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n8'"
              ><v-col
                cols="4"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
              >
                Current Facility
              </v-col>
              <v-col
                cols="8"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'mob-font-style'
                "
              >
                {{ memFacility ? memFacility.facility_name : "None Chosen" }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="12" sm="12">
            <v-row>
              <v-col
                cols="12"
                md="2"
                sm="12"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
                >County</v-col
              >
              <v-col
                cols="12"
                md="10"
                sm="12"
                :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n4'"
              >
                <v-autocomplete
                  :disabled="profile.choose_facility === false"
                  :items="
                    newCounties.length !== 0
                      ? newCounties
                      : ['search your county']
                  "
                  v-model="formData.county"
                  outlined
                  dense
                  :item-value="(item) => item"
                  :item-text="(item) => item.county_name"
                  label="search your county"
                  @change="getFacilities()"
                  :menu-props="{
                    scrollbar: 'thin',
                  }"
                ></v-autocomplete>
              </v-col> </v-row
          ></v-col>

          <v-col cols="12" md="12" sm="12" class="mt-n6">
            <v-row>
              <v-col
                cols="12"
                md="2"
                sm="12"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'main-font-style'
                    : 'mob-main-font-style'
                "
                >Preffered Facility</v-col
              >
              <v-col cols="12" md="10" sm="12">
                <v-autocomplete
                  v-model="formData.facility"
                  :items="
                    formData.county.county_name !== undefined &&
                    newFacilities.length !== 0
                      ? newFacilities
                      : Check
                      ? ['No Facilities found']
                      : ['loading']
                  "
                  outlined
                  dense
                  :disabled="formData.county.county_name === undefined"
                  :item-value="(item) => item"
                  :item-text="(item) => item.facility_name"
                  label=""
                ></v-autocomplete>
              </v-col> </v-row
          ></v-col>

          <v-col cols="12" md="12" sm="12" class="mt-n2">
            <v-row>
              <v-col cols="12" md="12" sm="12" class="mt-n6">
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                    sm="12"
                    :class="
                      $vuetify.breakpoint.mdAndUp
                        ? 'main-font-style'
                        : 'mob-main-font-style'
                    "
                    >Facility Level</v-col
                  >
                  <v-col cols="12" sm="10" md="4">
                    <v-text-field
                      disabled
                      label=""
                      v-model="formData.facility.level"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                    sm="12"
                    :class="
                      $vuetify.breakpoint.mdAndUp
                        ? 'main-font-style'
                        : 'mob-main-font-style'
                    "
                    >Facility Category</v-col
                  >
                  <v-col cols="12" sm="10" md="4">
                    <v-text-field
                      disabled
                      label=""
                      v-model="formData.facility.category"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col> </v-row
              ></v-col> </v-row
          ></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="">
        <v-spacer />

        <v-btn
          depressed
          v-if="$vuetify.breakpoint.mdAndUp"
          color="secondary white--text"
          @click="saveFacility()"
          :disabled="isDisabled"
        >
          <span>Update Facility</span>
        </v-btn>
        <v-btn
          depressed
          v-if="!$vuetify.breakpoint.mdAndUp"
          small
          color="secondary white--text"
          @click="saveFacility()"
          :disabled="isDisabled"
        >
          <span>Update Facility</span>
        </v-btn>
        <v-spacer />
      </v-card-actions>
      <v-card-text>
        <v-col cols="12" md="12" sm="12" class="mt-n2">
          <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="12" sm="6" md="10" class="mb-5">
              <div class="alert alert-info">
                <v-icon small color="#803432">mdi-alert-octagon</v-icon>
                Access to Service is subject to Full Payments of Premiums
              </div>

              <v-card class="mx-auto" outlined>
                <v-list height="350">
                  <v-subheader>FACILITIES</v-subheader>
                  <v-divider style="border: 1px solid grey" />

                  <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-content class="">
                      <v-row
                        ><v-col>{{ item.number }}</v-col>
                        <v-divider vertical />
                        <v-col>{{ item.text }}</v-col></v-row
                      >
                      <v-divider />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>

    <div class="text-center">
      <v-dialog persistent v-model="openDialog" width="600" height="600">
        <v-card>
          <v-card-title class="justify-center">
            <v-icon size="100" color="red">mdi-close-circle</v-icon>
          </v-card-title>
          <v-card-title class="justify-center service-text">
            Sevice Unavilable for Current Scheme
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="white--text"
              color="primary"
              :to="{ name: 'mainDashboard' }"
            >
              Go Back
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog
        persistent
        v-model="ratingDialog"
        :width="$vuetify.breakpoint.mdAndUp ? 400 : 300"
        :height="$vuetify.breakpoint.mdAndUp ? 600 : 300"
      >
        <v-card>
          <v-card-title>
            <v-spacer /><v-icon
              :size="$vuetify.breakpoint.mdAndUp ? 30 : 10"
              color="red"
              @click="ratingDialog = false"
              >mdi-close</v-icon
            ></v-card-title
          >
          <v-card-text class="rating-text mt-3">
            <div class="text-center">
              <span
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'font-style' : 'rate-font-style'
                "
              >
                {{ profile ? profile.rating_message : "" }}</span
              >
            </div>
          </v-card-text>
          <v-card-text>
            <div class="text-center">
              <v-rating
                v-model="ratingForm.rating"
                color="yellow darken-3"
                background-color="yellow darken-3"
                :size="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                half-increments
                hover
              ></v-rating>
            </div>
          </v-card-text>
          <v-card-text>
            <v-textarea
              dense
              outlined
              label="FeedBack(Optional)"
              :placeholder="
                $vuetify.breakpoint.mdAndUp ? 'feedback(optional)' : ''
              "
              v-model="ratingForm.feedback"
              ref="question"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="white--text mt-n3"
              color="primary"
              @click="submitRating()"
            >
              rate
            </v-btn>
            <v-spacer />
          </v-card-actions>
          <v-dialog v-model="linearDialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                submitting
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar">
        Thanks for your feedback

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import { AuthService } from "@/modules/auth";
import { EventBus } from "@/utils/eventBus";
export default {
  name: "facilitiesDashboard",
  components: {},

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Profile/getProfile", {
        id_number: AuthService.idNo,
        source: "WEB",
        type: "1",
      });
      v.$store.dispatch("Facilities/getCounties", {
        source: "WEB",
        text: "",
        phone: AuthService.phone,
        id_number: AuthService.idNo,
      });
    });
  },
  data() {
    return {
      newCounties: [],
      newFacilities: [],
      placeHolder: ["search your county"],
      county: "",
      formData: {
        county: {},
        facility: {},
      },
      items: [
        { text: "Level 1", number: "1" },
        { text: "Level 2", number: "2" },
        { text: "Level 3", number: "3" },
        { text: "Level 4", number: "4" },
        { text: "Level 5", number: "5" },
      ],
      countyData: {
        level: "",
        category: "",
      },
      typedText: "",
      dialog: true,
      ratingDialog: false,
      ratingForm: {
        feedback: "",
        rating: 0,
        source: "WEB",
        member_no: "",
      },
      snackbar: false,
      linearDialog: false,
      Check: false,
    };
  },

  computed: {
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
    counties() {
      return this.$store.getters["Facilities/facilitiesGetters"]("counties");
    },
    facilities() {
      return this.$store.getters["Facilities/facilitiesGetters"]("facilities");
    },
    memFacility() {
      return this.$store.getters["Profile/profileGetters"]("facility");
    },

    openDialog() {
      return this.profile
        ? this.profile.choose_facility === false
          ? true
          : false
        : false;
    },
    facilityDetails() {
      return this.$store.getters["Facilities/facilitiesGetters"](
        "facilityDetails"
      );
    },
    isDisabled() {
      return Object.keys(this.formData.facility).length === 0 ? true : false;
    },
  },
  methods: {
    getFacilities() {
      this.$store.dispatch("Facilities/getFacilities", {
        source: "WEB",
        phone: AuthService.phone,
        id: AuthService.idNo,
        facility_name: "",
        county_name: this.formData.county.county_name,
        county_code: this.formData.county.county_id,
      });
    },
    saveFacility() {
      const data = {
        allData: {
          phone: AuthService.phone,
          member_no: this.profile.member_number,
          source: "WEB",
          hospital_id: this.formData.facility.hospital_id,
        },
        idNo: AuthService.idNo,
        rating: this.profile.rating,
      };

      if (this.profile.facility_name === null) {
        this.$store.dispatch("Facilities/saveFacility", data);
      } else {
        this.$store.dispatch("Facilities/updateFacility", data);
      }
    },
    submitRating() {
      this.linearDialog = true;
      this.ratingForm.member_no = this.profile.member_number;
      this.$store.dispatch("Facilities/submitRating", this.ratingForm);
    },
  },
  watch: {
    counties: {
      handler: function () {
        if (this.counties.length > 0) {
          this.newCounties = [...this.counties];
        }
      },
    },
    facilities: {
      handler: function () {
        if (this.facilities.length > 0 || this.facilities.length === 0) {
          this.newFacilities = [...this.facilities];
        }
        if (this.facilities.length > 0) {
          this.Check = false;
        } else {
          this.Check = true;
        }
      },
    },
    profile: {
      handler: function () {
        if (this.profile) {
          this.$store.dispatch("Profile/getMemberFacility", {
            source: "WEB",
            member_no: this.profile.member_number,
            phone: AuthService.phone,
          });
        }
      },
    },
    memFacility: {
      handler: function () {
        if (this.memFacility) {
          this.$store.dispatch("Facilities/getFacilityDetails", {
            source: "WEB",
            facility_id: this.memFacility.facility_no,
            member_no: this.profile.member_number,
          });
        }
      },
    },
    facilityDetails: {
      handler: function () {
        if (this.facilityDetails) {
          this.countyData.level = this.facilityDetails.level;
          this.countyData.category = this.facilityDetails.category;
        }
      },
    },
  },
  mounted() {
    EventBus.$on("updateFacility", (e) => {
      this.$toast.success(e);
      this.$store.dispatch("Profile/getProfile", {
        id_number: AuthService.idNo,
        source: "WEB",
        type: "1",
      });
      this.formData.county = {};
      this.formData.facility = {};
    });
    EventBus.$on("error", () => {
      this.$toast.error("Error in Saving");
      this.formData.county = {};
      this.formData.facility = {};
    });
    EventBus.$on("openRatingBox", () => {
      this.ratingDialog = true;
    });
    EventBus.$on("openSnackBar", () => {
      this.linearDialog = false;
      this.ratingDialog = false;
      setTimeout(() => {
        this.snackbar = true;
      }, 2000);
    });
    EventBus.$on("closeDialog", () => {
      this.linearDialog = false;
      this.ratingDialog = false;
    });
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
